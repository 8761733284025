import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	ListItem,
	ListItemText,
	Modal,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { currenciesFormater } from '../utils/currencies/currencies-formater';
import { useContext, useEffect, useMemo, useState, useRef } from 'react';
import {
	CambiappContext,
	CambiappContextType,
} from '../context/cambiapp.context';
import { useNavigate } from 'react-router-dom';
import { StyledTableCell } from './new-order-steps/buy';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import moment from 'moment';
import { asyncHandler } from '../utils/async-handler';
import { convertTime } from '../utils/date';
import Countdown from 'react-countdown';
import { CommonTooltip } from './tooltip/common-tooltip';
import { showShifts } from '../utils/shift';
import { ShiftsTooltip } from './tooltip/shifts-tooltip';
import { FloatingGuaranteeShield } from './guarantee-shield/floating-guarantee-shield';
import { TextualGuaranteeShield } from './guarantee-shield/textual-guarantee-shield';
import { EditPublication } from './edit-publication.component';
import CircularProgress from '@mui/material/CircularProgress';
import { Currency } from '../interfaces/publication.interface';

interface IPublication {
	children: { [key: string]: any };
	currency: Currency;
}

interface IIdenttificationForm {
	type: 'circle' | 'square';
	bgColor1: string;
	bgColor2?: string;
	children: string;
}

export const IdentificationForm = ({
	type,
	bgColor1,
	bgColor2,
	children,
}: IIdenttificationForm) => {
	const blockStyle = {
		width: '0',
		height: '0',
		borderRight: `14px solid ${bgColor1}`,
		borderTop: '14px solid transparent',
		borderBottom: '14px solid transparent',
		position: 'absoulte' as unknown as undefined,
		right: '20px',
	};
	const blockStyle2 = {
		width: '0',
		height: '0',
		borderLeft: `14px solid ${bgColor2 || bgColor1}`,
		borderTop: '14px solid transparent',
		borderBottom: '14px solid transparent',
		position: 'absoulte' as unknown as undefined,
		left: '20px',
	};
	const circleStyle = {
		display: 'inline-block',
		width: '12px',
		height: '24px',
		backgroundColor: bgColor1,
		borderRadius: '15px 0 0 15px',
		textAlign: 'center' as unknown as undefined,
		position: 'absoulte' as unknown as undefined,
	};
	const circleStyle2 = {
		display: 'inline-block',
		width: '12px',
		height: '24px',
		backgroundColor: bgColor2 || bgColor1,
		borderRadius: '0 15px 15px 0',
		textAlign: 'center' as unknown as undefined,
		position: 'absoulte' as unknown as undefined,
	};
	return (
		<span
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'relative',
				maxWidth: '25%',
			}}
		>
			<span style={type === 'square' ? blockStyle : circleStyle} />
			<span style={{ position: 'absolute' }}>{children}</span>
			<span style={type === 'square' ? blockStyle2 : circleStyle2} />
		</span>
	);
};

export const Publication = ({ children, currency }: IPublication) => {
	const navigation = useNavigate();
	const [details, setDetails] = useState<any>();
	const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [isPauseDialogOpen, setPauseDialogOpen] = useState(false);
	const [isEditOpen, setEditOpen] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const availableDates = useMemo(() => {
		if (details && details.listingShifts)
			return details.listingShifts.filter((shift: any) => {
				return (
					moment(shift.date.split('T')[0])
						.add(shift.shift.split(':')[0], 'hours')
						.add(shift.shift.split(':')[1], 'minutes')
						.subtract(children.advanceMinimumRequired.split(':')[0], 'hours')
						.subtract(
							parseInt(children.advanceMinimumRequired.split(':')[1]) + 1,
							'minutes',
						)
						.isAfter(moment().add(3, 'hours')) && shift.isAvailable
				);
			}).length;
	}, [details, children.advanceMinimumRequired]);
	const expirationDateCountdown = useMemo(() => {
		if (details) {
			if (details.expirationDate) {
				const expirationDate = moment(details.expirationDate);
				return expirationDate.toDate();
			} else if (details.listingShifts) {
				const date = details.listingShifts.sort((a: any, b: any) =>
					moment(a.date.split('T')[0])
						.add(a.shift.split(':')[0], 'hours')
						.add(a.shift.split(':')[1], 'minutes')
						.isAfter(
							moment(b.date.split('T')[0])
								.add(b.shift.split(':')[0], 'hours')
								.add(b.shift.split(':')[1], 'minutes'),
						)
						? 1
						: -1,
				)[details.listingShifts.length - 1];
				const res = moment(date.date.split('T')[0])
					.add(date.shift.split(':')[0], 'hours')
					.add(date.shift.split(':')[1], 'minutes')
					.subtract(3, 'hours')
					.subtract(details.advanceMinimumRequired.split(':')[0], 'hours')
					.subtract(details.advanceMinimumRequired.split(':')[1], 'minutes');
				return res.toDate();
			}
		} else {
			return '';
		}
	}, [details]);
	const { nickName, totalAmount, unitPrice, type } = children;
	const { statuses, currencies } = useContext(
		CambiappContext,
	) as CambiappContextType;
	const formattedTotalAmount = currenciesFormater(totalAmount);
	const formattedUnitPrice = currenciesFormater(unitPrice, 'ARS');
	const itemText = children.listingBatches.map((batch: any) => {
		return {
			color: currency === 'USD' ? batch.billColor : '#666666',
			status: statuses.data && statuses.data.indexOf(batch.billStatus),
		};
	});
	const [isUnitPriceLoading, setIsUnitPriceLoading] = useState(false);
	const [isTotalAmountLoading, setIsTotalAmountLoading] = useState(false);
	const prevUnitPriceRef = useRef(children.unitPrice);
	const prevTotAmntRef = useRef(children.totalAmount);

	const [priceChanged, setPriceChanged] = useState(false);
	const [totalAmountChanged, setTotalAmountChanged] = useState(false);

	const currencySymbol = useMemo(() => {
		if (Array.isArray(currencies?.data)) {
			const foundCurrency = currencies.data.find((c) => c.code === currency);
			return foundCurrency ? foundCurrency.code : 'USD';
		}
		return 'USD';
	}, [currency, currencies?.data]);

	const currencyColor = useMemo(() => {
		if (Array.isArray(currencies?.data)) {
			const foundCurrency = currencies.data.find((c) => c.code === currency);
			return foundCurrency ? foundCurrency.color : '#48887B';
		}
		return '#48887B';
	}, [currency, currencies?.data]);

	const handlePriceChangedUpdate = (changed: any) => {
		setPriceChanged(changed);
	};

	const handleTotalAmountChangedUpdate = (changed: any) => {
		setTotalAmountChanged(changed);
	};

	useEffect(() => {
		if (isEditOpen && priceChanged) {
			setIsUnitPriceLoading(true);
		} else if (!isEditOpen && !priceChanged) {
			setIsUnitPriceLoading(false);
			setPriceChanged(false);
		} else if (!isEditOpen && prevUnitPriceRef.current !== children.unitPrice) {
			setIsUnitPriceLoading(false);
			setPriceChanged(false);
		}
		prevUnitPriceRef.current = children.unitPrice;
	}, [isEditOpen, priceChanged, children.unitPrice]);

	useEffect(() => {
		if (isEditOpen && totalAmountChanged) {
			setIsTotalAmountLoading(true);
		} else if (!isEditOpen && totalAmountChanged) {
			setIsTotalAmountLoading(false);
			setTotalAmountChanged(false);
		} else if (!isEditOpen && prevTotAmntRef.current !== children.totalAmount) {
			setIsTotalAmountLoading(false);
			setTotalAmountChanged(false);
		}
		prevTotAmntRef.current = children.totalAmount;
	}, [isEditOpen, totalAmountChanged, children.totalAmount]);

	const itemToShow = (
		<span
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-evenly',
			}}
		>
			<em
				style={{
					fontStyle: 'normal',
					width: '40%',
					color: children.isPartialAllowed
					? '#ffff00'
					: 'inherit',
				}}
			>
				{currencySymbol} {formattedTotalAmount[1]}
			</em>
			<IdentificationForm
				type={type === 'COMPRA' ? 'square' : 'circle'}
				bgColor1={itemText[0]?.color}
				bgColor2={itemText[1]?.color || itemText[0]?.color}
			>
				{itemText.map((item: any) => item.status + 1)}
			</IdentificationForm>
			<em
				style={{
					fontStyle: 'normal',
					width: '40%',
					color: children.isPartialAllowed
					? '#ffff00'
					: 'inherit',
				}}
			>
				{`${formattedUnitPrice[0]} ${formattedUnitPrice[1]}`}
			</em>
		</span>
	);

	useEffect(() => {
		if (!statuses.isLoading && statuses.data?.length === 0) statuses.refetch();
	}, [statuses]);

	const fetchDetails = async () => {
		const response = await asyncHandler.fetchPublicatioById(children.id);
		setDetails(response);
	};

	useEffect(() => {
		fetchDetails();
	}, [children.id, isEditOpen, refresh]);

	return (
		<>
			{isEditOpen ? (
				<EditPublication
					isOpen={isEditOpen}
					setOpen={setEditOpen}
					listingShifts={details.listingShifts}
					listingBatches={details.listingBatches}
					setRefresh={() => setRefresh(!refresh)}
					onChangePriceChanged={handlePriceChangedUpdate}
					onChangeTotalAmountChanged={handleTotalAmountChangedUpdate}
				>
					{children}
				</EditPublication>
			) : (
				<Modal
					open={isModalOpen}
					onClose={() => setModalOpen(false)}
					sx={{
						'display': 'flex',
						'flexDirection': 'column',
						'justifyContent': 'center',
						'alignItems': 'center',
						'rowGap': '25px',
						'margin': '0 auto',
						'backdropFilter': 'blur(5px)',
						'@media (max-width: 500px)': {
							rowGap: '5px',
						},
					}}
				>
					<>
						<Box
							sx={{
								maxWidth: 'max-content',
								maxHeight: 'max-content',
								boxShadow: 'none',
								borderRadius: 'none',
							}}
						>
							<Box
								sx={{
									margin: 0,
									padding: '10px 20px',
									backgroundColor: currencyColor,
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: '15px 15px 0 0',
									position: 'relative',
								}}
							>
								{children.isPaused && (
									<Box
										sx={{
											position: 'absolute',
											left: '12px',
											borderRadius: '50%',
											backgroundColor: '#191919',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											height: '0px',
											width: '60px',
											padding: '30px 0',
										}}
									>
										<Typography fontSize={12} fontWeight={600}>
											PAUSADA
										</Typography>
									</Box>
								)}
								<Typography>{children.nickName}</Typography>
								<Typography>{children.action?.toUpperCase()}</Typography>
								<CircleRoundedIcon
									sx={{
										position: 'absolute',
										top: '16px',
										right: '16px',
										fill: 'white',
										fontSize: '28px',
									}}
								/>
								<CancelIcon
									sx={{
										position: 'absolute',
										top: '15px',
										right: '15px',
										color: '#191919',
										fontSize: '30px',
									}}
									onClick={() => setModalOpen(false)}
								/>
							</Box>
							<TableContainer component={Paper} sx={{ marign: '8px' }}>
								<Table>
									<TableHead>
										<TableRow>
											<StyledTableCell sx={{ textAlign: 'center' }}>
												Total ({currency})
											</StyledTableCell>
											<StyledTableCell sx={{ textAlign: 'center' }}>
												Denominación
											</StyledTableCell>
											<StyledTableCell sx={{ textAlign: 'center' }}>
												Versión
											</StyledTableCell>
											<StyledTableCell sx={{ textAlign: 'center' }}>
												Estado
											</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{children.listingBatches?.map(
											(batch: any, index: number) => {
												return (
													<TableRow key={index}>
														<StyledTableCell sx={{ textAlign: 'center' }}>
															{currenciesFormater(batch.amount)}
														</StyledTableCell>
														<StyledTableCell sx={{ textAlign: 'center' }}>
															{batch.billDenomination.split('-')[0]}
														</StyledTableCell>
														<CommonTooltip
															title={batch.billVersion.split('/')[1]}
														>
															<StyledTableCell
																sx={{
																	textAlign: 'center',
																	color: batch.billColor,
																}}
															>
																{batch.billVersion.split('/')[0]}
															</StyledTableCell>
														</CommonTooltip>
														<CommonTooltip title={batch.billStatus}>
															<StyledTableCell sx={{ textAlign: 'center' }}>
																{statuses.data &&
																	statuses.data.indexOf(batch.billStatus) + 1}
															</StyledTableCell>
														</CommonTooltip>
													</TableRow>
												);
											},
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: 'repeat(4, 1fr)',
									gridTemplateRows: 'repeat(5, 1fr)',
									gridColumnGap: '20px',
									margin: 0,
									padding: '10px 20px',
									bgcolor: '#191919',
								}}
							>
								<Typography
									sx={{
										width: 'max-content',
										height: 'max-content',
										gridArea: '1 / 1 / 2 / 3',
									}}
								>
									Cantidad total
								</Typography>
								<Box
									sx={{
										borderRadius: '5px',
										padding: '5px 10px',
										width: '100%',
										height: 'max-content',
										gridArea: '1 / 3 / 2 / 5',
										bgcolor: '#000000',
									}}
								>
									<Typography
										sx={{
											width: 'auto',
											height: 'max-content',
											textAlign: 'right',
											color: children.isPartialAllowed
												? '#ffff00'
												: 'inherit',
											fontWeight: 600,
										}}
									>
										{isTotalAmountLoading ? (
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'flex-end',
													gap: '5px',
												}}
											>
												<Typography
													sx={{
														width: 'auto',
														height: 'max-content',
														textAlign: 'right',
														color: 'inherit',
														fontWeight: 600,
													}}
												>
													{currency}
												</Typography>
												<CircularProgress
													size="1rem"
													color="inherit"
													sx={{
														marginTop: '5px',
													}}
												/>
											</Box>
										) : (
											`${currency} ${
												currenciesFormater(children.totalAmount)?.[1]
											}`
										)}
									</Typography>
								</Box>
								<Typography
									sx={{
										width: 'max-content',
										height: 'max-content',
										gridArea: '2 / 1 / 3 / 3',
									}}
								>
									Precio unitario
								</Typography>
								<Box
									sx={{
										borderRadius: '5px',
										padding: '5px 10px',
										width: '100%',
										height: 'max-content',
										gridArea: '2 / 3 / 3 / 5',
										bgcolor: '#000000',
									}}
								>
									<Typography
										sx={{
											width: 'auto',
											height: 'max-content',
											textAlign: 'right',
											color: 'inherit',
											fontWeight: 600,
										}}
									>
										{isUnitPriceLoading ? (
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'flex-end',
													gap: '5px',
												}}
											>
												<Typography
													sx={{
														width: 'auto',
														height: 'max-content',
														textAlign: 'right',
														color: 'inherit',
														fontWeight: 600,
													}}
												>
													ARS
												</Typography>
												<CircularProgress
													size="1rem"
													color="inherit"
													sx={{
														marginTop: '5px',
													}}
												/>
											</Box>
										) : (
											currenciesFormater(children.unitPrice, 'ARS')
										)}
									</Typography>
								</Box>
								<Typography
									sx={{
										width: 'max-content',
										height: 'max-content',
										gridArea: '3 / 1 / 4 / 3',
									}}
								>
									Total
								</Typography>
								<Box
									sx={{
										borderRadius: '5px',
										padding: '5px 10px',
										width: '100%',
										height: 'max-content',
										gridArea: '3 / 3 / 4 / 5',
										bgcolor: '#000000',
									}}
								>
									<Typography
										sx={{
											width: 'auto',
											height: 'max-content',
											textAlign: 'right',
											color: children.isPartialAllowed
											? '#ffff00'
											: 'inherit',
											fontWeight: 600,
										}}
									>
										{isUnitPriceLoading || isTotalAmountLoading ? (
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'flex-end',
													gap: '5px',
												}}
											>
												<Typography
													sx={{
														width: 'auto',
														height: 'max-content',
														textAlign: 'right',
														color: 'inherit',
														fontWeight: 600,
													}}
												>
													ARS
												</Typography>
												<CircularProgress
													size="1rem"
													color="inherit"
													sx={{
														marginTop: '5px',
													}}
												/>
											</Box>
										) : (
											currenciesFormater(
												children.unitPrice * children.totalAmount,
												'ARS',
											)
										)}
									</Typography>
								</Box>
								{children.isPartialAllowed && (
									<Typography
										fontSize={15}
										style={{
											gridArea: '4 / 1 / 5 / 5',
										}}
									>
										Permite aceptación parcial por mínimo de{' '}
										<span style={{ color: '#ffff00' }}>
											{currency} {children.minimunPartialAmount}
										</span>
									</Typography>
								)}
								<Typography
									fontSize={15}
									style={{
										gridArea: '5 / 1 / 6 / 4',
										maxWidth: '260px',
									}}
								>
									Denominación mínima de billetes pesos a entregar al vendedor:
								</Typography>
								<Typography
									fontSize={14}
									sx={{
										margin: 'auto',
										bgcolor: '#000000',
										color: 'white',
										padding: '8px 10px',
										gridArea: '5 / 4 / 6 / 5',
										borderRadius: '5px',
									}}
								>
									{children.minimumDenominationToReceive}
								</Typography>
								{children.isGuaranteed && <TextualGuaranteeShield />}
							</Box>
							<Box
								sx={{
									margin: 0,
									padding: '5px 20px',
									backgroundColor: currencyColor,
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									borderRadius: '0 0 15px 15px',
								}}
							>
								<Box>
									<Box>
										Publicado:{' '}
										{moment(children.creationAt)
											.add(-3, 'hours')
											.format('DD/MM/YYYY')}{' '}
										{convertTime(
											children.creationAt.split('T')[1].slice(0, 5),
											'ARG',
										)}
									</Box>
									{expirationDateCountdown && (
										<>
											<Countdown
												date={expirationDateCountdown}
												zeroPadTime={2}
												daysInHours
												renderer={(props) => (
													<Typography>
														Vence en:{' '}
														{`${props.formatted.hours}:${props.formatted.minutes}:${props.formatted.seconds}`}
													</Typography>
												)}
											/>
										</>
									)}
								</Box>
								<Typography
									sx={{
										display: 'flex',
										alignItems: 'center',
										columnGap: '10px',
									}}
								>
									Citas disponibles:
									<ShiftsTooltip title={details && showShifts(details)}>
										<IdentificationForm type="circle" bgColor1="black">
											{availableDates}
										</IdentificationForm>
									</ShiftsTooltip>
								</Typography>
							</Box>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: '5px',
								alignItems: 'center',
								justifyContent: 'center',
								paddingTop: '10px',
							}}
						>
							{children.nickName.split(' ')[0] === 'Yo' &&
								availableDates > 0 &&
								(children.isPaused ? (
									<>
										<Button
											variant="contained"
											sx={{
												minWidth: '300px',
												maxWidth: '400px',
												width: '50%',
												padding: '10px 0',
												color: 'white',
												backgroundColor: '#191919',
												marginTop: '-10px',
											}}
											color="info"
											onClick={() => setPauseDialogOpen(true)}
										>
											ACTIVAR
										</Button>
										<Dialog
											open={isPauseDialogOpen}
											onClose={() => setPauseDialogOpen(false)}
											aria-labelledby="alert-dialog-title"
											aria-describedby="alert-dialog-description"
										>
											<DialogTitle id="alert-dialog-title">
												¿Esta seguro que desea activar esta publicacion?
											</DialogTitle>
											<DialogActions>
												<Button
													color="error"
													onClick={() => setPauseDialogOpen(false)}
												>
													NO
												</Button>
												<Button
													color="success"
													onClick={() => {
														setPauseDialogOpen(false);
														asyncHandler.changePublicationPauseStatus(
															children.id,
															children.isPaused,
															() => setModalOpen(false),
														);
													}}
													autoFocus
												>
													SI
												</Button>
											</DialogActions>
										</Dialog>
									</>
								) : (
									<>
										<Button
											variant="contained"
											sx={{
												minWidth: '300px',
												maxWidth: '400px',
												width: '50%',
												padding: '10px 0',
												color: 'white',
												backgroundColor: '#191919',
											}}
											onClick={() => setPauseDialogOpen(true)}
										>
											PAUSAR / MODIFICAR
										</Button>
										<Dialog
											open={isPauseDialogOpen}
											onClose={() => setPauseDialogOpen(false)}
											aria-labelledby="alert-dialog-title"
											aria-describedby="alert-dialog-description"
										>
											<DialogTitle id="alert-dialog-title">
												¿Esta seguro que desea pausar esta publicacion?
											</DialogTitle>
											<DialogActions>
												<Button
													color="error"
													onClick={() => setPauseDialogOpen(false)}
												>
													NO
												</Button>
												<Button
													color="success"
													onClick={() => {
														setPauseDialogOpen(false);
														asyncHandler.changePublicationPauseStatus(
															children.id,
															children.isPaused,
															() => setModalOpen(false),
														);
													}}
													autoFocus
												>
													SI
												</Button>
											</DialogActions>
										</Dialog>
									</>
								))}
							{children.nickName.split(' ')[0] === 'Yo' &&
								availableDates > 0 &&
								children.isPaused && (
									<>
										<Button
											variant="contained"
											sx={{
												minWidth: '300px',
												maxWidth: '400px',
												width: '50%',
												padding: '10px 0',
												color: 'white',
												backgroundColor: '#191919',
											}}
											color="warning"
											onClick={() => setEditOpen(true)}
										>
											MODIFICAR
										</Button>
									</>
								)}
							{children.nickName.split(' ')[0] === 'Yo' &&
								availableDates > 0 && (
									<>
										<Button
											variant="contained"
											sx={{
												minWidth: '300px',
												maxWidth: '400px',
												width: '50%',
												padding: '10px 0',
												color: 'white',
												backgroundColor: '#ED3237',
											}}
											color="error"
											onClick={() => setDeleteDialogOpen(true)}
										>
											ELIMINAR
										</Button>
										<Dialog
											open={isDeleteDialogOpen}
											onClose={() => setDeleteDialogOpen(false)}
											aria-labelledby="alert-dialog-title"
											aria-describedby="alert-dialog-description"
										>
											<DialogTitle id="alert-dialog-title">
												¿Esta seguro que desea elminiar esta publicacion?
											</DialogTitle>
											<DialogActions>
												<Button
													color="error"
													onClick={() => setDeleteDialogOpen(false)}
												>
													NO
												</Button>
												<Button
													color="success"
													onClick={() => {
														setDeleteDialogOpen(false);
														asyncHandler.deletePublicatioById(children.id, () =>
															setModalOpen(false),
														);
													}}
													autoFocus
												>
													SI
												</Button>
											</DialogActions>
										</Dialog>
									</>
								)}
						</Box>
						{children.nickName.split(' ')[0] !== 'Yo' && availableDates > 0 && (
							<Button
								variant="contained"
								sx={{
									minWidth: '300px',
									maxWidth: '400px',
									width: '50%',
									padding: '10px 0',
									bgcolor: '#05A550',
									color: 'white',
								}}
								onClick={() => navigation(`${children.id}`)}
							>
								{children.type === 'VENTA'
									? children.isPartialAllowed
										? 'Comprar todo'
										: 'Comprar'
									: children.isPartialAllowed
									? 'Vender todo'
									: 'Vender'}
							</Button>
						)}
						{children.nickName.split(' ')[0] !== 'Yo' &&
							availableDates > 0 &&
							children.isPartialAllowed && (
								<Button
									variant="contained"
									sx={{
										minWidth: '300px',
										maxWidth: '400px',
										width: '50%',
										padding: '10px 0',
										bgcolor: '#05A550',
										color: 'white',
									}}
									onClick={() => navigation(`${children.id}/partial`)}
								>
									{children.type === 'VENTA'
										? 'Comprar menor cantidad'
										: 'Vender menor cantidad'}
								</Button>
							)}
					</>
				</Modal>
			)}
			<ListItem
				sx={{
					'position': 'relative',
					'textAlign': 'center',
					'padding': 0,
					'display': 'flex',
					'alignItems': 'stretch',
					'margin': 0,
					'rowGap': '5px',
					':hover': {
						cursor: 'pointer',
					},
					'boxShadow': 'none',
					'borderRadius': 'none',
				}}
				onClick={() => setModalOpen(true)}
			>
				{children.isGuaranteed && <FloatingGuaranteeShield />}
				<ListItemText
					primary={
						children.isPaused ? (
							<Typography lineHeight={1} fontSize={12} fontWeight={800}>
								PAUSADA
							</Typography>
						) : (
							<Typography lineHeight={1} fontSize={12} fontWeight={800}>
								{children.action?.toUpperCase()}
							</Typography>
						)
					}
					secondary={
						children.isPaused ? (
							<></>
						) : (
							<Typography lineHeight={1} fontSize={12} fontWeight={400}>
								{nickName.length > 10 ? nickName.slice(0, 9) + '...' : nickName}
							</Typography>
						)
					}
					sx={{
						margin: 0,
						padding: '2.5px 0',
						backgroundColor: currencyColor,
						display: 'flex',
						flexDirection: 'column-reverse',
						justifyContent: 'center',
						borderRadius: '5px 0 0 5px',
						minWidth: '100px',
						maxWidth: '74px',
						textOverflow: 'ellipsis',
					}}
				/>

				<ListItemText
					secondary={
						<Typography lineHeight={1} fontSize={14} fontWeight={500}>
							{itemToShow}
						</Typography>
					}
					sx={{
						margin: 0,
						padding: '2.5px',
						backgroundColor: '#000000',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				/>
				{expirationDateCountdown && (
					<>
						<Countdown
							date={expirationDateCountdown}
							zeroPadTime={2}
							daysInHours
							renderer={(props) => (
								<div
									style={{
										margin: 0,
										backgroundColor: currencyColor,
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										borderRadius: '0 5px 5px 0',
										width: '50px',
										padding: '2.5px 0',
									}}
								>
									<Typography lineHeight={1} fontSize={12}>
										{props.formatted.hours}:{props.formatted.minutes}:
										{props.formatted.seconds}
									</Typography>
								</div>
							)}
						/>
					</>
				)}
			</ListItem>
		</>
	);
};
