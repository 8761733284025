import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initializeFirebase } from './firebase-push-notification';
import reportWebVitals from './reportWebVitals';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme, { darkTheme } from './theme/mui-theme';
import { CambiappProvider } from './context/cambiapp.context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

initializeFirebase();

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient();

root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<CambiappProvider>
				<ThemeProvider theme={darkTheme}>
					<CssBaseline enableColorScheme />
					<App />
				</ThemeProvider>
			</CambiappProvider>
		</QueryClientProvider>
	</React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
