import numeral from 'numeral';

export const currenciesFormater = (
	amount: number,
	currency?: string | undefined,
) => {
	switch (currency) {
		case 'USD':
			return [`USD `, `${numeral(amount).format('0,0.00')}`];
		case 'ARS':
			return [`ARS `, `${numeral(amount).format('0,0.00')}`];
		default:
			return [``, `${numeral(amount).format('0,0')}`];
	}
};

export const decimalFormater = (amount: number) => {
	const roundedAmount = Math.round(amount);
	return numeral(roundedAmount).format('0,0');
};
