import {
	Box,
	InputAdornment,
	MenuItem,
	Select,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
	CambiappContext,
	CambiappContextType,
} from '../../../context/cambiapp.context';
import { currenciesFormater } from '../../../utils/currencies/currencies-formater';
import { truncateDecimals } from '../../../utils/formatter';
import { CommonButton } from '../../button/common-button';
import { InformationTooltip } from '../../tooltip/information-tooltip';
import { BatchTableRow } from '../batch-table/batch-table-row.component';
import { BatchTable } from '../batch-table/batch-table.component';

export const SecondStepComponent = ({ nextStep, previousStep }: any) => {
	const { newBuyOrder, setNewBuyOrder, arsDenominations, currency } =
		useContext(CambiappContext) as CambiappContextType;
	const [price, setPrice] = useState(newBuyOrder.price);
	const [amountPesos, setAmountPesos] = useState(newBuyOrder.amountPesos);
	const [isPartialAllowed, setPartialAllowed] = useState(
		newBuyOrder.isPartialAllowed,
	);
	const [minimunPartialAmount, setMinimunPartialAmount] = useState(
		newBuyOrder.minimunPartialAmount,
	);
	const handlePriceChange = (e: any) => {
		const regex = /^[0-9.\b]+$/;
		if (e.target.value === '' || regex.test(e.target.value)) {
			e.target.value && e.target.value > 0
				? setPrice(e.target.value)
				: setPrice('');
		}
	};
	const handleNextStep = () => {
		if (price && amountPesos) {
			if (
				isPartialAllowed &&
				((currency === 'BRL' && minimunPartialAmount >= 500) || (currency !== 'BRL' && minimunPartialAmount >= 100)) && 
				newBuyOrder.batch!.amount > minimunPartialAmount &&
				minimunPartialAmount % newBuyOrder.batch!.denomination.value === 0
			) {
				setPrice(truncateDecimals(price, 2));
				setNewBuyOrder({
					...newBuyOrder,
					price,
					amountPesos,
					isPartialAllowed,
					minimunPartialAmount,
				});
				nextStep();
			} else if (isPartialAllowed) {
				alert( `La cantidad parcial mínima ingresada no es válida. Debe ser mayor o igual a ${currency === 'BRL' ? '500' : '100'}, menor a ${
					newBuyOrder.batch!.amount} y múltiplo de ${newBuyOrder.batch!.denomination.value}`,)	
			} else if (!isPartialAllowed) {
				setPrice(truncateDecimals(price, 2));
				setNewBuyOrder({
					...newBuyOrder,
					price,
					amountPesos,
					isPartialAllowed: false,
					minimunPartialAmount: 0,
				});
				nextStep();
			}
		}
	};
	const handlePartialSell = (e: any) => {
		setPartialAllowed(e.target.checked);
	};
	const handlePartialPriceChange = (e: any) => {
		setMinimunPartialAmount(e.target.value);
	};
	useEffect(() => {
		if (!arsDenominations.isLoading && arsDenominations.data?.length === 0)
			arsDenominations.refetch();
	}, [arsDenominations, arsDenominations.data]);
	return (
		<Box sx={{ margin: '0 25px' }}>
			<Typography
				sx={{
					textAlign: 'center',
					fontWeight: 600,
					fontSize: 18,
					marginBottom: '50px',
				}}
			>
				Complete la publicación
			</Typography>
			<Typography sx={{ marginBottom: '16px', fontWeight: 800 }}>
				Resumen del lote
			</Typography>
			<BatchTable>
				<BatchTableRow
					amount={newBuyOrder.batch?.amount as number}
					denomination={newBuyOrder.batch?.denomination.name as string}
					descriptionTooltip={newBuyOrder.batch?.version.description as string}
					versionColor={newBuyOrder.batch?.version.color as string}
					versionName={newBuyOrder.batch?.version.name as string}
					statusTooltip={newBuyOrder.batch?.status.split('-')[1] as string}
					statusName={newBuyOrder.batch?.status.split('-')[0] as string}
				/>
			</BatchTable>
			<Typography sx={{ marginTop: '16px' }}>
				Cantidad total a publicar: {currency}{' '}
				{newBuyOrder.batch && currenciesFormater(newBuyOrder.batch.amount)}
			</Typography>
			<Typography sx={{ marginTop: '16px' }}>
				Permitir aceptación parcial
				<Switch
					aria-label="Permitir venta parcial"
					onClick={handlePartialSell}
					checked={isPartialAllowed}
					disabled={currency === 'BRL' ? (newBuyOrder.batch?.amount as number) <= 500 : (newBuyOrder.batch?.amount as number) <= 100}
				/>
				<InformationTooltip
					title={
						'Si permitis la aceptación parcial, cualquier operador podra vender menor cantidad que la total que publicaste. Luego de confirmarse la venta parcial, la publicacion quedara actualizada por la cantidad restante, para que otros operadores puedan seguir vendiendo.'
					}
				/>
			</Typography>
			{isPartialAllowed && (
				<>
					<Typography
						sx={{
							fontWeight: 800,
							margin: '50px 0 20px',
						}}
					>
						Consigná la cantidad parcial mínima que te pueden vender
					</Typography>
					<Box
						sx={{ display: 'flex', alignItems: 'center', columnGap: '16px' }}
					>
						<TextField
							placeholder="0,00"
							value={minimunPartialAmount || ''}
							variant="standard"
							sx={{
								backgroundColor: '#000000',
								padding: '10px',
								borderRadius: '5px',
								color: 'white',
							}}
							onChange={(e) => handlePartialPriceChange(e)}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">{currency}</InputAdornment>
								),
								disableUnderline: true,
							}}
						/>
						
						<InformationTooltip
							title={`Debe ser mayor o igual a ${currency} ${currency === 'BRL' ? '500' : '100'} y múltiplo de la denominación de tus ${currency}. Por ejemplo, si tus ${currency} son 'cincuenta', la cantidad parcial debe ser múltiplo 50.`
						}
						/>
					</Box>
				</>
			)}
			<Typography
				sx={{
					fontWeight: 800,
					margin: '50px 0 20px',
				}}
			>
				Indicá el precio unitario en pesos ARS al que querés comprar
			</Typography>
			<TextField
				placeholder="0,00"
				value={price || ''}
				variant="standard"
				sx={{
					backgroundColor: '#000000',
					padding: '10px',
					borderRadius: '5px',
				}}
				onChange={(e) => handlePriceChange(e)}
				InputProps={{
					startAdornment: <InputAdornment position="start">ARS</InputAdornment>,
					disableUnderline: true,
				}}
			/>
			<Typography
				sx={{
					fontWeight: 800,
					margin: '50px 0 20px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				Seleccioná la denominación mínima de los billetes pesos ARS que
				entregarás al vendedor
				<InformationTooltip
					title={
						'El vendedor aceptará billetes ARS de la denominación indicada, o superior (en caso de existir).'
					}
				/>
			</Typography>
			<Select
				fullWidth
				value={amountPesos?.value || 0}
				variant="standard"
				sx={{
					backgroundColor: '#000000',
					padding: '10px',
					borderRadius: '5px',
				}}
				disableUnderline
			>
				<MenuItem value={0} sx={{ display: 'none' }}>
					Seleccionar
				</MenuItem>
				{arsDenominations.isSuccess &&
					arsDenominations.data
						?.sort((a, b) => b.amount - a.amount)
						.map((arsDenomination) => {
							return (
								<MenuItem
									value={arsDenomination.amount}
									key={arsDenomination.id}
									onClick={() =>
										setAmountPesos({
											name: arsDenomination.name,
											value: arsDenomination.amount,
										})
									}
								>
									{arsDenomination.name}
								</MenuItem>
							);
						})}
			</Select>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					padding: '50px 0',
				}}
			>
				<CommonButton
					action={previousStep}
					sxBgcolor={'#666666'}
					maxHeight="40px"
				>
					<Typography fontSize={12} color={'white'} fontWeight={600}>
						ATRÁS
					</Typography>
				</CommonButton>
				<CommonButton
					action={handleNextStep}
					sxBgcolor={'#05A550'}
					maxHeight="40px"
				>
					<Typography fontSize={12} color={'white'} fontWeight={600}>
						CONTINUAR
					</Typography>
				</CommonButton>
			</Box>
		</Box>
	);
};
