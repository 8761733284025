import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	styled,
	tableCellClasses,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
	CambiappContext,
	CambiappContextType,
} from '../../../context/cambiapp.context';
import {
	currenciesFormater,
	decimalFormater,
} from '../../../utils/currencies/currencies-formater';
import moment from 'moment';
import { CommonModal } from '../../modal/common-modal';
import { DetailContainer } from '../fourth-step-detail-container';
import { CommonModalContainer } from '../../modal/common-modal-container';
import { CommonButton } from '../../button/common-button';
import { AmrSelection } from '../../amr-selection/amr-selection';
import { ExpirationDateSelection } from '../../expiration-date-selection/expiration-date-selection';
import { ButtonsContainer } from '../fourth-step-buttons-container';
import { ConfirmationButtonsContainer } from '../fourth-step-confirmation-buttons-container';
import { CommonTooltip } from '../../tooltip/common-tooltip';
import { InformationTooltip } from '../../tooltip/information-tooltip';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#666666',
		color: theme.palette.common.white,
		padding: '8px',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		padding: '8px',
		backgroundColor: '#000000',
		border: 'unset',
	},
}));

export const FourthStepComponent = ({
	awaitResponse,
	previousStep,
	nextStep,
}: any) => {
	const [canChange, setCanChange] = useState(false);
	const [maxHours, setMaxHours] = useState(0);
	const [maxMinutes, setMaxMinutes] = useState(30);
	const [showMessage, setShowMessage] = useState(false);
	const [isAmrModalOpen, setAmrModalOpen] = useState(false);
	const [isExpirationTimeModalOpen, setExpirationTimeModalOpen] =
		useState(false);
	const {
		newSellOrder,
		selectedAvailableAppointments,
		orderAmr,
		setOrderAmr,
		expirationDate,
		setExpirationDate,
		currency,
	} = useContext(CambiappContext) as CambiappContextType;
	const [newAmr, setNewAmr] = useState(orderAmr);
	const [newExpiration, setNewExpiration] = useState(
		moment(
			selectedAvailableAppointments[selectedAvailableAppointments.length - 1]
				.date,
		)
			.add(3, 'hours')
			.add(
				parseInt(
					selectedAvailableAppointments[
						selectedAvailableAppointments.length - 1
					].shift.split(':')[0],
				) - parseInt(orderAmr.split(':')[0]),
				'hours',
			)
			.add(
				parseInt(
					selectedAvailableAppointments[
						selectedAvailableAppointments.length - 1
					].shift.split(':')[1],
				) - parseInt(orderAmr.split(':')[1]),
				'minutes',
			),
	);
	const maxExpiration = moment(
		selectedAvailableAppointments[selectedAvailableAppointments.length - 1]
			.date,
	)
		.add(3, 'hours')
		.set(
			'hours',
			parseInt(
				selectedAvailableAppointments[
					selectedAvailableAppointments.length - 1
				].shift.split(':')[0],
			),
		)
		.set(
			'minutes',
			parseInt(
				selectedAvailableAppointments[
					selectedAvailableAppointments.length - 1
				].shift.split(':')[1],
			),
		)
		.subtract(parseInt(orderAmr.split(':')[0]), 'hours')
		.subtract(parseInt(orderAmr.split(':')[1]), 'minutes');

	const handleAmrChange = () => {
		setOrderAmr(newAmr);
		setExpirationDate(
			moment(
				selectedAvailableAppointments[selectedAvailableAppointments.length - 1]
					.date,
			)
				.add(3, 'hours')
				.set(
					'hours',
					parseInt(
						selectedAvailableAppointments[
							selectedAvailableAppointments.length - 1
						].shift.split(':')[0],
					),
				)
				.set(
					'minutes',
					parseInt(
						selectedAvailableAppointments[
							selectedAvailableAppointments.length - 1
						].shift.split(':')[1],
					),
				)
				.subtract(newAmr.split(':')[0], 'hours')
				.subtract(newAmr.split(':')[1], 'minutes')
				.toISOString(),
		);
		setAmrModalOpen(false);
	};

	const handleExpirationTimeChange = () => {
		setExpirationDate(newExpiration.toISOString());
		setExpirationTimeModalOpen(false);
	};

	useEffect(() => {
		const firstAvailableTurn = moment(selectedAvailableAppointments[0].date)
			.add(3, 'hours')
			.set(
				'hours',
				parseInt(selectedAvailableAppointments[0].shift.split(':')[0]),
			)
			.set(
				'minutes',
				parseInt(selectedAvailableAppointments[0].shift.split(':')[1]),
			)
			.subtract(3, 'hours')
			.toISOString();
		const minimunExpiration = moment()
			.subtract(3, 'hours')
			.add(15, 'minutes')
			.toISOString();
		const hoursDifference = moment(firstAvailableTurn).diff(
			minimunExpiration,
			'hours',
		);
		const minutesDifference = moment(firstAvailableTurn).diff(
			minimunExpiration,
			'minutes',
		);

		if (minutesDifference > 30) setCanChange(true);
		setMaxHours(hoursDifference);
		setMaxMinutes(minutesDifference - 60 * hoursDifference);
	}, [selectedAvailableAppointments]);

	useEffect(() => {
		handleExpirationTimeChange();
	}, []);

	const checkScreenSize = (): boolean => {
		let smallScreenSize: boolean;
		if (window.screen.width > 321) {
			smallScreenSize = false;
		} else {
			smallScreenSize = true;
		}
		return smallScreenSize;
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				rowGap: '25px',
				padding: '25px',
			}}
		>
			<Typography sx={{ fontWeight: 800 }}>
				Resumen de la publicación
			</Typography>
			<TableContainer component={Paper} sx={{ marign: '8px' }}>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableCell sx={{ textAlign: 'center' }}>
								Total ({currency})
							</StyledTableCell>
							<StyledTableCell sx={{ textAlign: 'center' }}>
								Denomicación
							</StyledTableCell>
							<StyledTableCell sx={{ textAlign: 'center' }}>
								Versión
							</StyledTableCell>
							<StyledTableCell sx={{ textAlign: 'center' }}>
								Estado
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{newSellOrder.firstBatch && (
							<TableRow>
								<StyledTableCell sx={{ textAlign: 'center' }}>
									{currenciesFormater(newSellOrder.firstBatch.amount)}
								</StyledTableCell>
								<StyledTableCell sx={{ textAlign: 'center' }}>
									{newSellOrder.firstBatch.denomination.name}
								</StyledTableCell>
								<CommonTooltip
									title={newSellOrder.firstBatch.version.description}
								>
									<StyledTableCell
										sx={{
											textAlign: 'center',
											color: newSellOrder.firstBatch.version.color,
										}}
									>
										{newSellOrder.firstBatch.version.name}
									</StyledTableCell>
								</CommonTooltip>
								<CommonTooltip
									title={newSellOrder.firstBatch.status.split('-')[1]}
								>
									<StyledTableCell sx={{ textAlign: 'center' }}>
										{newSellOrder.firstBatch.status.split('-')[0]}
									</StyledTableCell>
								</CommonTooltip>
							</TableRow>
						)}
						{newSellOrder.secondBatch && (
							<TableRow>
								<StyledTableCell sx={{ textAlign: 'center' }}>
									{currenciesFormater(newSellOrder.secondBatch.amount)}
								</StyledTableCell>
								<StyledTableCell sx={{ textAlign: 'center' }}>
									{newSellOrder.secondBatch.denomination.name}
								</StyledTableCell>
								<CommonTooltip
									title={newSellOrder.secondBatch.version.description}
								>
									<StyledTableCell
										sx={{
											textAlign: 'center',
											color: newSellOrder.secondBatch.version.color,
										}}
									>
										{newSellOrder.secondBatch.version.name}
									</StyledTableCell>
								</CommonTooltip>
								<CommonTooltip
									title={newSellOrder.secondBatch.status.split('-')[1]}
								>
									<StyledTableCell sx={{ textAlign: 'center' }}>
										{newSellOrder.secondBatch.status.split('-')[0]}
									</StyledTableCell>
								</CommonTooltip>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<DetailContainer>
				<Typography>Cantidad total a publicar</Typography>
				<Typography>
					{newSellOrder.firstBatch &&
						`${currency} ${
							currenciesFormater(
								newSellOrder.firstBatch.amount +
									(newSellOrder.secondBatch?.amount || 0),
							)[1]
						}`}
				</Typography>
			</DetailContainer>
			<DetailContainer>
				<Typography>Precio unitario</Typography>
				<Typography>
					{newSellOrder.price &&
						`${currenciesFormater(parseFloat(newSellOrder.price), 'ARS')[0]} ${
							currenciesFormater(parseFloat(newSellOrder.price), 'ARS')[1]
						}`}
				</Typography>
			</DetailContainer>
			<DetailContainer>
				<Typography>Importe total a recibir</Typography>
				<Typography>
					{newSellOrder.firstBatch &&
						newSellOrder.price &&
						'ARS ' +
							decimalFormater(
								(newSellOrder.firstBatch.amount +
									(newSellOrder.secondBatch?.amount || 0)) *
									parseFloat(newSellOrder.price),
							)}
				</Typography>
			</DetailContainer>
			{newSellOrder.isPartialAllowed && (
				<DetailContainer>
					<Typography>Permite aceptación parcial por un mínimo de</Typography>
					<Typography sx={{ whiteSpace: 'nowrap' }}>
						{`${currency} ` +
							currenciesFormater(newSellOrder.minimunPartialAmount)[1]}
					</Typography>
				</DetailContainer>
			)}
			<DetailContainer>
				<Typography>
					Denominación mínima de billetes pesos a recibir del comprador
				</Typography>
				<Typography>{newSellOrder.minimunPesos?.name}</Typography>
			</DetailContainer>
			<DetailContainer>
				<Typography>Anticipación Mínima Requerida (AMR)</Typography>
				<Typography>
					{orderAmr.split(':')[0]} hs. {orderAmr.split(':')[1]} min.
				</Typography>
				<ButtonsContainer>
					<CommonButton
						sxBgcolor={'#CB7246'}
						disabled={!canChange}
						action={() => {
							setAmrModalOpen(true);
						}}
					>
						Ampliar
					</CommonButton>
					<InformationTooltip
						title={`La AMR indica con qué anticipación a cada una de las citas, el comprador puede confirmar la adquisición de tu oferta, e incide en el vencimiento de la publicación. Podés dejar la AMR predeterminada de 30 minutos, o ampliarla. Tené en cuenta tu agenda y ubicación geográfica, para estimar cuanto tardarías en llegar al domicilio de la Casa, en caso de confirmarse la operación.`}
					/>
				</ButtonsContainer>
			</DetailContainer>
			<DetailContainer>
				<Typography>Vencimiento de la publicación:</Typography>
				<Typography>
					{moment(expirationDate).format('DD/MM/YY HH:mm [hs]')}
				</Typography>
				<ButtonsContainer>
					<CommonButton
						sxBgcolor={'#CB7246'}
						disabled={!canChange}
						action={() => setExpirationTimeModalOpen(true)}
					>
						Adelantar
					</CommonButton>
					<InformationTooltip
						title={`Se muestra el vencimiento de la publicación, considerando la AMR respecto de la última cita disponible. Podés adelantar el vencimiento hasta el ímite permitido, considerando que la vigencia mínima de una publicación es de 15 minutos. Durante la vigencia los compradores podrán aceptar tu oferta. Pero, si así lo deseas, podrás eliminar la publicación en cualquier momento antes de que venza y antes de que sea aceptada.`}
					/>
				</ButtonsContainer>
			</DetailContainer>
			<Box
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					paddingBottom: '12.5px',
					columnGap: '25px',
				}}
			>
				<Typography>
					Lista de citas posibles (LCP)
					{` `}
					<InformationTooltip
						title={`Si durante la vigencia de tu publicación, alguna de las citas que seleccionaste coincide con la reservada en operaciones confirmadas por otros usuarios, dicha cita se considerará caducada, y desaparecerá automáticamente de tu publicación. En el caso de que ello suceda con todas las citas que elegiste, tu publicación completa quedará caducada y será archivada. Podrás luego reciclarla, actualizando la LCP.`}
					/>
				</Typography>
				<Box
					style={{
						width: '100%',
						display: 'flex',
						padding: '12.5px 0',
						flexDirection: 'column',
					}}
				>
					{selectedAvailableAppointments.map(({ date }, index) => {
						if (
							!selectedAvailableAppointments[index - 1] ||
							selectedAvailableAppointments[index - 1].date !== date
						)
							return (
								<>
									<Typography>
										{moment(date)
											.add(3, 'hours')
											.format('dddd D MMMM')
											.toUpperCase()}
									</Typography>
									<Box
										style={{
											display: 'flex',
											flexWrap: 'wrap',
											columnGap: '10px',
											rowGap: '10px',
										}}
									>
										{selectedAvailableAppointments.map((appointment) => {
											if (appointment.date === date)
												return (
													<Typography
														style={{
															padding: '1px 2px',
															backgroundColor: '#3C8846',
															borderRadius: '5px',
														}}
													>
														{appointment.shift}
													</Typography>
												);
											return <></>;
										})}
									</Box>
								</>
							);
						return <></>;
					})}
				</Box>
			</Box>
			<ConfirmationButtonsContainer smallScreenSize={checkScreenSize()}>
				<Box sx={{ display: 'flex', columnGap: '16px', alignItems: 'center' }}>
					<CommonButton
						sxBgcolor={'#05A550'}
						action={showMessage ? () => {} : nextStep}
						disabled={awaitResponse}
					>
						<Typography fontSize={12} color={'white'} fontWeight={600}>
							PUBLICAR
						</Typography>
						<Typography fontSize={12} color={'white'} fontWeight={600}>
							OFERTA
						</Typography>
					</CommonButton>
					<InformationTooltip
						title={`Verificá todas las condiciones de la oferta, ya que luego de su
					publicación, no podrá ser modificada. El primer comprador que acepte las condiciones de la publicación será
					quien adquiera el lote ofrecido, quedando la operación automáticamente
					confirmada.`}
					/>
				</Box>
				<CommonButton
					action={previousStep}
					sxBgcolor={'#666666'}
					maxHeight="40px"
				>
					<Typography fontSize={12} color={'white'} fontWeight={600}>
						ATRÁS
					</Typography>
				</CommonButton>
			</ConfirmationButtonsContainer>
			<CommonModal
				active={isAmrModalOpen}
				close={() => setAmrModalOpen(false)}
				confirmButtonAction={showMessage ? () => {} : handleAmrChange}
			>
				<Box>
					<Typography>
						Seleccione la nueva AMR. Tenga en cuenta que la fecha de vencimiento
						se actualizará en base a este valor.
					</Typography>
					<CommonModalContainer>
						<AmrSelection
							action={setNewAmr}
							maxHours={maxHours}
							maxMinutes={maxMinutes}
							setShowMessage={setShowMessage}
						>
							{orderAmr}
						</AmrSelection>
					</CommonModalContainer>
					{showMessage && (
						<Typography color="red">
							Porfavor, ingrese un valor de AMR entre 00:30 y {maxHours}:
							{maxMinutes}
						</Typography>
					)}
				</Box>
			</CommonModal>
			<CommonModal
				active={isExpirationTimeModalOpen}
				close={() => setExpirationTimeModalOpen(false)}
				confirmButtonAction={
					showMessage ? () => {} : handleExpirationTimeChange
				}
			>
				<Box>
					<Typography>Vencimiento de la publicación:</Typography>
					<CommonModalContainer>
						<ExpirationDateSelection
							action={setNewExpiration}
							closestDate={maxExpiration}
							expirationDate={moment(expirationDate)}
							orderAmr={orderAmr}
							setShowMessage={setShowMessage}
						/>
					</CommonModalContainer>
					{showMessage && (
						<Typography color="red">
							El vencimiento ingresado no es válido. Por favor ingrese un
							vencimiento entre el{' '}
							{moment().add(15, 'minutes').format('DD/MM/YYYY [a las] HH:mm')} y{' '}
							{moment(maxExpiration).format(
								`${
									moment().add(15, 'minutes').day() !==
									moment(maxExpiration).day()
										? '[el] DD/MM/YYYY [a]'
										: ''
								}[las] HH:mm`,
							)}
							.
						</Typography>
					)}
				</Box>
			</CommonModal>
		</Box>
	);
};
