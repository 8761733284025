import { styled, TableCell, tableCellClasses } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#666666',
		color: theme.palette.common.white,
		padding: '8px',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		padding: '8px',
		backgroundColor: '#000000',
		border: 'unset',
	},
}));
