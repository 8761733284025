export const truncateDecimals = (
	priceWithDecimals: string,
	amountOfDecimals: number,
) => {
	const dotPosition = priceWithDecimals.indexOf('.');
	if (dotPosition >= 0) {
		let [fullAmount, cents] = priceWithDecimals.split('.');
		cents =
			cents.length > amountOfDecimals
				? cents.slice(0, amountOfDecimals)
				: cents;
		return `${fullAmount}.${cents}`;
	}
	return `${priceWithDecimals}.00`;
};
