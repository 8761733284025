import { TableRow } from '@mui/material';
import React from 'react';
import { CommonTooltip } from '../../../components/tooltip/common-tooltip';
import { StyledTableCell } from './batch-table-cell.component';

interface TableRowProps {
	amount: number;
	denomination: string;
	descriptionTooltip?: string;
	versionColor: string;
	versionName: string;
	statusTooltip?: string;
	statusName: string;
}

export const BatchTableRow = ({
	amount,
	denomination,
	descriptionTooltip,
	versionColor,
	versionName,
	statusTooltip,
	statusName,
}: TableRowProps) => {
	return (
		<TableRow>
			<StyledTableCell sx={{ textAlign: 'center' }}>{amount}</StyledTableCell>
			<StyledTableCell sx={{ textAlign: 'center' }}>
				{denomination}
			</StyledTableCell>
			<CommonTooltip title={descriptionTooltip as string}>
				<StyledTableCell
					sx={{
						textAlign: 'center',
						color: versionColor,
					}}
				>
					{versionName}
				</StyledTableCell>
			</CommonTooltip>
			<CommonTooltip title={statusTooltip as string}>
				<StyledTableCell sx={{ textAlign: 'center' }}>
					{statusName}
				</StyledTableCell>
			</CommonTooltip>
		</TableRow>
	);
};
