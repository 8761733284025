import { TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface AmrSelectionProps {
	children: string;
	action: (newAmr: string) => void;
	maxHours: number;
	maxMinutes: number;
	setShowMessage: (newState: boolean) => void;
}

export const AmrSelection = ({
	children,
	action,
	maxHours,
	maxMinutes,
	setShowMessage,
}: AmrSelectionProps) => {
	const numbersRegex = /[a-zA-Z.*+?^${}()|[\]\\]/g;
	const [hoursValue, setHoursValue] = useState(children.split(':')[0]);
	const [minutesValue, setMinutesValue] = useState(children.split(':')[1]);

	const handleHoursChange = (event: any) => {
		event.target.value = event.target.value.replace(numbersRegex, '');
		if (event.target.value.length > 2)
			event.target.value = event.target.value.slice(-2);
		setHoursValue(event.target.value);
	};
	const handleMinutesChange = (event: any) => {
		event.target.value = event.target.value.replace(numbersRegex, '');
		if (event.target.value.length > 2)
			event.target.value = event.target.value.slice(-2);
		if (event.target.value > 59) event.target.value = '59';
		setMinutesValue(event.target.value);
	};

	useEffect(() => {
		if (
			parseInt(hoursValue) > maxHours ||
			(parseInt(hoursValue) >= maxHours &&
				parseInt(minutesValue) > maxMinutes) ||
			(parseInt(hoursValue) === 0 && parseInt(minutesValue) < 30) ||
			!minutesValue ||
			!hoursValue
		)
			setShowMessage(true);
		else setShowMessage(false);
		action(
			`${
				parseInt(hoursValue) >= 10 ? hoursValue : '0' + parseInt(hoursValue)
			}:${
				parseInt(minutesValue) >= 10
					? minutesValue
					: '0' + parseInt(minutesValue)
			}`,
		);
	}, [hoursValue, minutesValue, maxHours, maxMinutes, action, setShowMessage]);

	return (
		<>
			<TextField
				size="small"
				placeholder={hoursValue}
				value={hoursValue}
				onChange={handleHoursChange}
				sx={{
					'width': '4ch',
					'margin': 0,
					'padding': 0,
					'textAlign': 'center',
					'& .MuiInputBase-input': {
						padding: '0',
						textAlign: 'center',
						color: 'black',
					},
					'& .MuiOutlinedInput-notchedOutline': {
						border: '2px solid black',
					},
				}}
			/>
			<Typography>hs.</Typography>
			<TextField
				size="small"
				placeholder={minutesValue}
				value={minutesValue}
				onChange={handleMinutesChange}
				sx={{
					'width': '4ch',
					'margin': 0,
					'padding': 0,
					'textAlign': 'center',
					'& .MuiInputBase-input': {
						padding: '0',
						textAlign: 'center',
						color: 'black',
					},
					'& .MuiOutlinedInput-notchedOutline': {
						border: '2px solid black',
					},
				}}
			/>
			<Typography>min.</Typography>
		</>
	);
};
