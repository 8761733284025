import { Box, Typography } from '@mui/material';
import { asyncHandler } from '../../../utils/async-handler';
import { Fragment, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import {
	CambiappContext,
	CambiappContextType,
	IAvailableAppointments,
} from '../../../context/cambiapp.context';
import { CommonButton } from '../../button/common-button';
import { InformationTooltip } from '../../tooltip/information-tooltip';

export const ThirdStepComponent = ({ previousStep, nextStep }: any) => {
	const { setSelectedAvailableAppointments, orderAmr, setOrderAmr } =
		useContext(CambiappContext) as CambiappContextType;
	const [appointments, setAppointments] = useState<any[]>([]);
	const hourValue = orderAmr.split(':')[0];
	const minutesValue = orderAmr.split(':')[1];
	const [time, setTime] = useState(
		moment()
			.utc(true)
			.add(hourValue, 'hours')
			.add(minutesValue, 'minutes')
			.format(),
	);
	const [selectedDates, setSelectedDates] = useState<IAvailableAppointments[]>(
		[],
	);

	const addOrRemoveDate = (dateToEvaluate: IAvailableAppointments) => {
		if (
			selectedDates.some((date) => {
				if (
					date.date === dateToEvaluate.date &&
					date.shift === dateToEvaluate.shift
				)
					return true;
				return false;
			})
		) {
			const newSelectedDates = selectedDates.filter((date) => {
				if (date.date === dateToEvaluate.date)
					return (
						date.shift !== dateToEvaluate.shift &&
						date.date === dateToEvaluate.date
					);
				return true;
			});
			setSelectedDates(newSelectedDates);
		} else {
			setSelectedDates([...selectedDates, dateToEvaluate]);
		}
	};

	useEffect(() => {
		setSelectedDates([]);
		const retrieveAppointments = async () => {
			let dates;
			if (hourValue && minutesValue)
				setTime(
					moment()
						.utc(true)
						.add(hourValue, 'hours')
						.add(minutesValue, 'minutes')
						.format(),
				);
			dates = await asyncHandler.fetchAppointments(time);
			setAppointments(dates);
		};
		retrieveAppointments();
	}, [hourValue, minutesValue, time]);

	useEffect(() => {
		setSelectedDates(
			selectedDates
				.sort((a, b) => {
					if (a.shift.split(':')[0] === b.shift.split(':')[0]) {
						if (a.shift.split(':')[1] > b.shift.split(':')[1]) return 1;
						if (a.shift.split(':')[1] < b.shift.split(':')[1]) return -1;
					}
					if (a.shift.split(':')[0] > b.shift.split(':')[0]) return 1;
					if (a.shift.split(':')[0] < b.shift.split(':')[0]) return -1;
					return 0;
				})
				.sort((a, b) => {
					if (a.date.split('T')[0] > b.date.split('T')[0]) return 1;
					if (a.date.split('T')[0] < b.date.split('T')[0]) return -1;
					return 0;
				}),
		);
	}, [selectedDates]);

	const selectAllAppointments = () => {
		const availableAppointments: IAvailableAppointments[] = [];
		appointments?.map((appointment) => {
			return appointment.hours.forEach((hour: any) => {
				const date = {
					date: appointment.date,
					shift: hour.shift,
				};
				availableAppointments.push(date);
			});
		});
		if (selectedDates.length === availableAppointments.length) {
			setSelectedDates([]);
		} else {
			setSelectedDates(availableAppointments);
		}
	};

	const handleNextStep = () => {
		if (selectedDates.length > 0) {
			setOrderAmr(`${hourValue}:${minutesValue}`);
			setSelectedAvailableAppointments(selectedDates);
			nextStep();
		}
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				padding: '25px',
			}}
		>
			<Typography
				sx={{
					textAlign: 'center',
					fontWeight: 600,
					fontSize: 18,
					marginBottom: '50px',
				}}
			>
				Citas posibles
			</Typography>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					marginBottom: '25px',
				}}
			>
				<Typography
					sx={{
						fontWeight: 800,
						margin: '0 20px',
						textAlign: 'justify',
					}}
				>
					Seleccionar qué días y horarios alternativos proponés para la cita de
					transacción en la Casa
				</Typography>
				<InformationTooltip
					title={`La cita es la fecha y hora en la que se realizará la transacción en la Casa. Seleccioná una, varias, o todas las citas alternativas que se encuentren disponibles. El vendedor que acepte tu demanda elegirá una de esas alternativas, y la misma quedará fijada como cita de transacción, no pudiendo modificarse.`}
				/>
			</Box>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginBottom: '16px',
				}}
			>
				<CommonButton
					action={() => {
						selectAllAppointments();
					}}
					sxBgcolor={'#666666'}
				>
					<Typography fontSize={12} color={'white'} fontWeight={600}>
						Seleccionar todas
					</Typography>
				</CommonButton>
			</Box>
			{appointments &&
				appointments.length > 0 &&
				appointments.map((appointment) => {
					return (
						<Box
							key={appointment.date}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								marginBottom: '25px',
							}}
						>
							<Typography>
								{moment(appointment.date)
									.add(3, 'hours')
									.format('dddd D MMMM')
									.toUpperCase()}
							</Typography>
							<Box
								sx={{
									display: 'flex',
									flexWrap: 'wrap',
									justifyContent: 'center',
								}}
							>
								{appointment.hours.map((hour: any) => {
									const dateObject = {
										date: appointment.date,
										shift: hour.shift,
									};
									if (hour.available)
										return (
											<Typography
												sx={{
													display: 'inline-block',
													bgcolor: selectedDates.some(
														(date) =>
															date.date === dateObject.date &&
															date.shift === dateObject.shift,
													)
														? '#3C8846'
														: '#405F8E',
													margin: '2px',
													padding: '1px',
													cursor: 'pointer',
												}}
												key={`${appointment.date}${hour.shift}`}
												onClick={() => {
													addOrRemoveDate(dateObject);
												}}
											>
												{hour.shift}
											</Typography>
										);
									return (
										<Fragment
											key={`${appointment.date}${hour.shift}`}
										></Fragment>
									);
								})}
							</Box>
						</Box>
					);
				})}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<CommonButton
					action={previousStep}
					sxBgcolor={'#666666'}
					maxHeight="40px"
				>
					<Typography fontSize={12} color={'white'} fontWeight={600}>
						ATRÁS
					</Typography>
				</CommonButton>
				<CommonButton
					action={handleNextStep}
					sxBgcolor={'#05A550'}
					maxHeight="40px"
				>
					<Typography fontSize={12} color={'white'} fontWeight={600}>
						CONTINUAR
					</Typography>
				</CommonButton>
			</Box>
		</Box>
	);
};
