import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { asyncHandler } from '../../utils/async-handler';
import { useNavigate } from 'react-router-dom';
import './login.styles.css';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { initializeFirebase } from '../../firebase-push-notification';

const Login = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState<string>();
	const [password, setPassword] = useState<string>();
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const handleEmailChange = (e: any) => setEmail(e.target.value);
	const handlePasswordChange = (e: any) => setPassword(e.target.value);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();
	};

	const styles = {
		root: {
			'& input:-webkit-autofill': {
				'-webkit-box-shadow': '0 0 0 100px #266798 inset !important',
				'background-clip': 'content-box !important',
			},
		},
	};

	async function handleLogin() {
		if (email && password) {
			initializeFirebase();
			await asyncHandler.loginHandler(email, password, navigate);
		}
	}

	return (
		<Box className="login">
			<img src={'/cambiapp_logo.png'} alt="Logo" width={250} />
			<Box className="login-content">
				<TextField
					label="User"
					variant="outlined"
					onChange={handleEmailChange}
					type="email"
					InputProps={{
						sx: styles.root,
					}}
				/>
				<TextField
					label="Password"
					variant="outlined"
					onChange={handlePasswordChange}
					type={showPassword ? 'text' : 'password'}
					InputProps={{
						sx: styles.root,
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
								>
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<Button
					variant="contained"
					size="large"
					sx={{ textTransform: 'capitalize' }}
					onClick={handleLogin}
				>
					Login
				</Button>
			</Box>
		</Box>
	);
};

export default Login;
