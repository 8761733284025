import moment from 'moment';
import { IAvailableAppointments } from '../../context/cambiapp.context';

export const convertTime = (time: string, timezone: 'UTC' | 'ARG') => {
	let numUTC: number;
	if (timezone === 'UTC') {
		numUTC = 3;
	} else {
		numUTC = -3;
	}
	const hours = parseFloat(time.split(':')[0]) + numUTC;
	let res: string;
	if (hours < 0) {
		res = 24 + hours + ':' + time.split(':')[1];
	} else {
		if (hours < 10) {
			res = '0' + hours + ':' + time.split(':')[1];
		} else {
			if (hours >= 24) {
				res = '0' + (hours - 24) + ':' + time.split(':')[1];
			} else {
				res = hours + ':' + time.split(':')[1];
			}
		}
	}

	return res;
};

export const convertAppointmentTime = async (
	arrays: IAvailableAppointments[],
): Promise<IAvailableAppointments[]> => {
	await arrays.forEach((array) => {
		array.shift = convertTime(array.shift, 'UTC');
	});
	return arrays;
};

export const convertNegativeTime = (num: number) => {
	let res: string;
	if (num < 0) {
		res = '00';
	} else {
		if (num < 10) {
			res = '0' + num;
		} else {
			res = num.toString();
		}
	}

	return res;
};

export const getTimeDifference = (startDate: string) => {
	const date1 = moment(startDate);
	const date2 = moment();
	const diff = date1.diff(date2, 'second', true);
	const days = Math.floor(diff / 86400);
	const hours = Math.floor((diff / 3600) % 24);
	const minutes = Math.floor((diff / 60) % 60);
	return `${convertNegativeTime(days)}:${convertNegativeTime(
		hours,
	)}:${convertNegativeTime(minutes)}`;
};
