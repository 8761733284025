import { Box } from '@mui/material';

export const ConfirmationButtonsContainer = ({
	children,
	smallScreenSize,
}: any) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row-reverse',
				width: '100%',
				justifyContent: 'space-between',
				gap: smallScreenSize ? '24px' : null,
			}}
		>
			{children}
		</Box>
	);
};
