import {
	Box,
	FormControlLabel,
	InputAdornment,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
	CambiappContext,
	CambiappContextType,
	IVersion,
} from '../../../context/cambiapp.context';
import { CommonButton } from '../../button/common-button';
import AddIcon from '@mui/icons-material/Add';
import { InformationTooltip } from '../../tooltip/information-tooltip';
import { BatchTable } from '../batch-table/batch-table.component';
import { BatchTableRow } from '../batch-table/batch-table-row.component';

export const FirstStepComponent = ({ nextStep }: any) => {
	const {
		statuses,
		currency,
		newSellOrder,
		setNewSellOrder,
		denominations,
		availableUsdVersions,
		setAvailableUsdVersions,
	} = useContext(CambiappContext) as CambiappContextType;
	const [secondBatchInsertion, setSecondBatchInsertion] = useState(false);
	const [firstBatchAmount, setFirstBatchAmount] = useState(
		newSellOrder.firstBatch?.amount,
	);
	const [secondBatchAmount, setSecondBatchAmount] = useState(
		newSellOrder.secondBatch?.amount,
	);
	const [firstBatchDenomination, setFirstBatchDenomination] = useState<
		{ name: string; value: number } | undefined
	>(newSellOrder.firstBatch?.denomination);
	const [secondBatchDenomination, setSecondBatchDenomination] = useState<
		{ name: string; value: number } | undefined
	>(newSellOrder.secondBatch?.denomination);
	const [firstBatchVersion, setFirstBatchVersion] = useState<IVersion>();
	const [secondBatchVersion, setSecondBatchVersion] = useState<IVersion>();
	const [firstBatchStatus, setFirstBatchStatus] = useState(
		newSellOrder.firstBatch?.status,
	);
	const [secondBatchStatus, setSecondBatchStatus] = useState(
		newSellOrder.secondBatch?.status,
	);
	const [availableVersions, setAvailableVersions] = useState<IVersion[]>([]);

	const handleFirstBatchAmountChange = (e: any) => {
		const regex = /^[0-9\b]+$/;
		if (e.target.value === '' || regex.test(e.target.value)) {
			setFirstBatchAmount(parseInt(e.target.value));
		}
	};
	const handleSecondBatchAmountChange = (e: any) => {
		const regex = /^[0-9\b]+$/;
		if (e.target.value === '' || regex.test(e.target.value)) {
			setSecondBatchAmount(parseInt(e.target.value));
		}
	};
	const handleSecondBatch = () => {
		if (
			firstBatchAmount &&
			firstBatchDenomination &&
			firstBatchVersion &&
			firstBatchStatus
		) {
			setAvailableVersions([]);
			setNewSellOrder({
				...newSellOrder,
				type: 'VENTA',
				firstBatch: {
					amount: firstBatchAmount,
					denomination: firstBatchDenomination,
					version: firstBatchVersion,
					status: firstBatchStatus,
				},
			});
			setSecondBatchInsertion(!secondBatchInsertion);
		}
	};
	const handleNextStep = () => {
		if (
			firstBatchAmount &&
			firstBatchDenomination &&
			firstBatchVersion &&
			firstBatchStatus &&
			currency
		) {
			if (currency !== 'BRL' && firstBatchAmount >= 100) {
				setNewSellOrder({
					...newSellOrder,
					type: 'VENTA',
					firstBatch: {
						amount: firstBatchAmount,
						denomination: firstBatchDenomination,
						version: firstBatchVersion,
						status: firstBatchStatus,
					},
				});
				if (!secondBatchInsertion) nextStep();
			} else if (currency === 'BRL' && firstBatchAmount >= 500) {
				setNewSellOrder({
					...newSellOrder,
					type: 'VENTA',
					firstBatch: {
						amount: firstBatchAmount,
						denomination: firstBatchDenomination,
						version: firstBatchVersion,
						status: firstBatchStatus,
					},
				});
				if (!secondBatchInsertion) nextStep();
			} else if (
				currency !== 'BRL' &&
				firstBatchAmount < 100 &&
				!secondBatchAmount
			) {
				alert(
					`La publicación debe tener una cantidad igual o mayor a ${currency} 100.`,
				);
			} else if (
				currency === 'BRL' &&
				firstBatchAmount < 500 &&
				!secondBatchAmount
			) {
				alert(
					`La publicación debe tener una cantidad igual o mayor a ${currency} 500.`,
				);
			}
		} else {
			alert('Por favor, complete la información del primer lote.');
		}
		if (
			secondBatchAmount &&
			secondBatchDenomination &&
			secondBatchVersion &&
			secondBatchStatus &&
			currency
		) {
			if (currency !== 'BRL' && firstBatchAmount! + secondBatchAmount >= 100) {
				setNewSellOrder({
					...newSellOrder,
					secondBatch: {
						amount: secondBatchAmount,
						denomination: secondBatchDenomination,
						version: secondBatchVersion,
						status: secondBatchStatus,
					},
				});
				nextStep();
			} else if (
				currency === 'BRL' &&
				firstBatchAmount! + secondBatchAmount >= 500
			) {
				setNewSellOrder({
					...newSellOrder,
					secondBatch: {
						amount: secondBatchAmount,
						denomination: secondBatchDenomination,
						version: secondBatchVersion,
						status: secondBatchStatus,
					},
				});
				nextStep();
			} else if (
				currency !== 'BRL' &&
				firstBatchAmount! + secondBatchAmount < 100
			) {
				alert(
					`La publicación debe tener una cantidad igual o mayor a ${currency} 100.`,
				);
			} else if (
				currency === 'BRL' &&
				firstBatchAmount! + secondBatchAmount < 500
			) {
				alert(
					`La publicación debe tener una cantidad igual o mayor a ${currency} 500.`,
				);
			}
		} else if (secondBatchInsertion) {
			alert(
				'Por favor, complete la información del segundo lote o cancelelo presionando en "Atrás".',
			);
		}
	};

	useEffect(() => {
		setAvailableUsdVersions(availableVersions);
	}, [
		availableVersions,
		setAvailableUsdVersions,
		availableUsdVersions,
		denominations,
	]);

	useEffect(() => {
		if (!denominations.isLoading && denominations.data?.length === 0)
			denominations.refetch();
		if (!statuses.isLoading && statuses.data?.length === 0) statuses.refetch();
	}, [denominations, statuses]);

	useEffect(() => {
		if (currency !== 'USD') {
			setFirstBatchVersion(availableVersions[0]);
			setFirstBatchStatus(`${1}-${statuses.data?.[0]}`);
			if (secondBatchInsertion) {
				setSecondBatchVersion(availableVersions[0]);
				setSecondBatchStatus(`${1}-${statuses.data?.[0]}`);
			}
		}
	}, [currency, availableVersions, statuses.data, secondBatchInsertion]);

	return (
		<Box
			sx={{
				margin: '0 50px',
				padding: '25px 0',
				display: 'flex',
				flexDirection: 'column',
				rowGap: '25px',
			}}
		>
			{!secondBatchInsertion && (
				<>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							columnGap: '15px',
						}}
					>
						<Typography variant="h6">Ingrese el primer lote</Typography>
						<InformationTooltip title="Podés cargar en ésta publicación hasta 2 lotes con diferente cantidad, denominación, versión y/o estado. La denominación, versión y estado que indiques para los billetes de éste lote se considerarán “mínimas”. Por lo tanto se entenderá que, al concretarse la operación, el comprador acepta recibir también billetes en iguales o en mejores condiciones que las publicadas, en cualquier proporción. Se considerará que están en mejores condiciones cuando son de mayor denominación, versión más nueva, o se encuentran en mejor estado que lo especificado en la publicación." />
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							columnGap: '15px',
						}}
					>
						<Typography sx={{ fontWeight: 800 }}>
							Indicá la cantidad de {currency} que querés vender
						</Typography>
						<InformationTooltip
							title={`La cantidad mínima es de ${currency} ${
								currency === 'BRL' ? '500' : '100'
							}. No se pueden ingresar centavos.`}
						/>
					</Box>
					<TextField
						variant="standard"
						sx={{
							backgroundColor: '#000000',
							padding: '10px',
							borderRadius: '5px',
							color: 'white',
						}}
						onChange={(e) => handleFirstBatchAmountChange(e)}
						placeholder="0,00"
						value={firstBatchAmount || ''}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">{currency}</InputAdornment>
							),
							disableUnderline: true,
						}}
					/>
					{denominations.isSuccess && denominations.data.length > 0 && (
						<>
							<Typography sx={{ fontWeight: 800 }}>
								Seleccioná la denominación mínima de los billetes {currency} que
								ofrecés
							</Typography>
							<Select
								value={firstBatchDenomination?.value || 0}
								displayEmpty
								variant="standard"
								sx={{
									backgroundColor: '#000000',
									padding: '10px',
									borderRadius: '5px',
								}}
								disableUnderline
							>
								<MenuItem value={0} sx={{ display: 'none' }}>
									Seleccionar
								</MenuItem>
								{denominations.data
									.sort((a, b) => b.amount - a.amount)
									.map((usdDenomination) => {
										return (
											<MenuItem
												value={usdDenomination.amount}
												key={usdDenomination.id}
												onClick={() => {
													setAvailableVersions(usdDenomination.versions);
													setFirstBatchDenomination({
														name: usdDenomination.name,
														value: usdDenomination.amount,
													});
												}}
											>
												{usdDenomination.name}
											</MenuItem>
										);
									})}
							</Select>

							{currency === 'USD' && (
								<RadioGroup>
									{availableVersions.length > 0 && (
										<>
											<Typography sx={{ fontWeight: 800 }}>
												Seleccioná la versión de los billetes {currency} que
												ofrecés
											</Typography>
											{availableVersions.map((availableVersion) => {
												return (
													<FormControlLabel
														sx={{ color: availableVersion.color }}
														value={availableVersion.name}
														control={
															<Radio
																checked={
																	firstBatchVersion?.name ===
																	availableVersion.name
																}
															/>
														}
														label={`${availableVersion.name} ${availableVersion.description}`}
														key={availableVersion.id}
														onClick={() =>
															setFirstBatchVersion(availableVersion)
														}
													/>
												);
											})}
										</>
									)}
								</RadioGroup>
							)}
						</>
					)}
					{firstBatchVersion && statuses.isSuccess && (
						<>
							<RadioGroup>
								<Typography sx={{ fontWeight: 800 }}>
									Seleccioná el estado en que se encuentran los billetes{' '}
									{currency} que ofrecés
								</Typography>
								{statuses.data.map((eachStatus, index) => {
									return (
										<FormControlLabel
											key={index}
											value={index + 1}
											control={
												<Radio
													checked={
														firstBatchStatus === `${index + 1}-${eachStatus}`
													}
												/>
											}
											label={`${index + 1}-${eachStatus}`}
											onClick={() =>
												setFirstBatchStatus(`${index + 1}-${eachStatus}`)
											}
										/>
									);
								})}
							</RadioGroup>
						</>
					)}
				</>
			)}
			{secondBatchInsertion && (
				<>
					<Typography sx={{ fontWeight: 800 }}>Lote ya cargado</Typography>
					<BatchTable>
						<BatchTableRow
							amount={newSellOrder.firstBatch?.amount as number}
							denomination={
								newSellOrder.firstBatch?.denomination.name as string
							}
							descriptionTooltip={
								newSellOrder.firstBatch?.version.description as string
							}
							versionColor={newSellOrder.firstBatch?.version.color as string}
							versionName={newSellOrder.firstBatch?.version.name as string}
							statusTooltip={newSellOrder.firstBatch?.status.split('-')[1]}
							statusName={
								newSellOrder.firstBatch?.status.split('-')[0] as string
							}
						/>
					</BatchTable>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							columnGap: '15px',
						}}
					>
						<Typography variant="h6">Ingrese el segundo lote</Typography>
						<InformationTooltip
							title={
								'Podés cargar en ésta publicación hasta 2 lotes con diferente cantidad, denominación versión y/o estado. La denominación, versión y estado que indiques para los billetes de éste lote se considerarán “mínimas”. Por lo tanto se entenderá que, al concretarse la operación, el comprador acepta recibir también billetes en iguales o en mejores condiciones que las publicadas, en cualquier proporción. Se considerará que están en mejores condiciones cuando son de mayor denominación, versión más nueva, o se encuentran en mejor estado que lo especificado en la publicación.'
							}
						/>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							columnGap: '15px',
						}}
					>
						<Typography sx={{ fontWeight: 800 }}>
							Indicá la cantidad de {currency} que querés vender
						</Typography>
						<InformationTooltip
							title={`La cantidad mínima es de ${currency} ${
								currency === 'BRL' ? '500' : '100'
							}. No se pueden ingresar centavos.`}
						/>
					</Box>
					<TextField
						variant="standard"
						sx={{
							backgroundColor: '#000000',
							padding: '10px',
							borderRadius: '5px',
							color: 'white',
						}}
						onChange={(e) => handleSecondBatchAmountChange(e)}
						placeholder="0,00"
						value={secondBatchAmount || ''}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">{currency}</InputAdornment>
							),
							disableUnderline: true,
						}}
					/>
					{denominations.isSuccess && (
						<>
							<Typography sx={{ fontWeight: 800 }}>
								Seleccioná la denominación mínima de los billetes {currency} que
								ofrecés
							</Typography>
							<Select
								value={secondBatchDenomination?.value || 0}
								displayEmpty
								variant="standard"
								sx={{
									backgroundColor: '#000000',
									padding: '10px',
									borderRadius: '5px',
								}}
								disableUnderline
							>
								<MenuItem value={0} sx={{ display: 'none' }}>
									Seleccionar
								</MenuItem>
								{denominations.data.map((usdDenomination) => {
									return (
										<MenuItem
											value={usdDenomination.amount}
											key={usdDenomination.id}
											onClick={() => {
												setAvailableVersions(usdDenomination.versions);
												setSecondBatchDenomination({
													name: usdDenomination.name,
													value: usdDenomination.amount,
												});
											}}
										>
											{usdDenomination.name}
										</MenuItem>
									);
								})}
							</Select>
							{currency === 'USD' && (
								<RadioGroup>
									{availableVersions.length > 0 && (
										<>
											<Typography sx={{ fontWeight: 800 }}>
												Seleccioná la versión de los billetes {currency} que
												ofrecés
											</Typography>
											{availableVersions.map((availableVersion) => {
												return (
													<FormControlLabel
														sx={{ color: availableVersion.color }}
														value={availableVersion.name}
														control={
															<Radio
																checked={
																	secondBatchVersion?.name ===
																	availableVersion.name
																}
															/>
														}
														label={`${availableVersion.name} ${availableVersion.description}`}
														key={availableVersion.id}
														onClick={() =>
															setSecondBatchVersion(availableVersion)
														}
													/>
												);
											})}
										</>
									)}
								</RadioGroup>
							)}
						</>
					)}
					{secondBatchVersion && statuses.isSuccess && (
						<>
							<RadioGroup>
								<Typography sx={{ fontWeight: 800 }}>
									Seleccioná el estado en que se encuentran los billetes{' '}
									{currency} que ofrecés
								</Typography>
								{statuses.data.map((eachStatus, index) => {
									return (
										<FormControlLabel
											key={index}
											value={index + 1}
											control={
												<Radio
													checked={
														secondBatchStatus === `${index + 1}-${eachStatus}`
													}
												/>
											}
											label={`${index + 1}-${eachStatus}`}
											onClick={() =>
												setSecondBatchStatus(`${index + 1}-${eachStatus}`)
											}
										/>
									);
								})}
							</RadioGroup>
						</>
					)}
				</>
			)}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					columnGap: '25px',
				}}
			>
				<CommonButton
					action={handleSecondBatch}
					EndIcon={!secondBatchInsertion ? AddIcon : undefined}
					sxBgcolor={'#666666'}
				>
					<Typography
						fontSize={12}
						color={'white'}
						fontWeight={600}
						whiteSpace={'nowrap'}
						overflow={'hidden'}
						marginRight={'5px'}
					>
						{!secondBatchInsertion ? 'Agregar nuevo lote' : 'Atrás'}
					</Typography>
				</CommonButton>
				<CommonButton
					action={handleNextStep}
					sxBgcolor={'#05A550'}
					maxHeight="40px"
					minWidth="75px"
				>
					<Typography fontSize={12} color={'white'} fontWeight={600}>
						CONTINUAR
					</Typography>
				</CommonButton>
			</Box>
		</Box>
	);
};
