/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import { TopBar } from '../components/topbar.component';
import { Outlet } from 'react-router-dom';

const MainLayout = () => {
	return (
		<Box>
			<TopBar />
			<Outlet />
		</Box>
	);
};

export default MainLayout;
