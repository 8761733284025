import { Box } from '@mui/material';
import { StepperComponent } from '../../components/stepper.component';
import { useContext, useEffect, useState } from 'react';
import {
	FirstStepComponent,
	SecondStepComponent,
	ThirdStepComponent,
	FourthStepComponent,
	ApprovedPublication,
} from '../../components/new-order-steps/buy';
import {
	CambiappContext,
	CambiappContextType,
} from '../../context/cambiapp.context';
import { asyncHandler } from '../../utils/async-handler';
import { ListingType } from '../../interfaces/publication.interface';
import { convertAppointmentTime } from '../../utils/date';
import moment from 'moment';

const NewPublication = () => {
	const {
		newBuyOrder,
		selectedAvailableAppointments,
		orderAmr,
		expirationDate,
		currency,
	} = useContext(CambiappContext) as CambiappContextType;
	const [awaitResponse, setAwaitResponse] = useState(false);
	const [publishedPublication, setPublishedPublication] =
		useState<boolean>(false);
	const [activeStep, setActiveStep] = useState(0);
	const nextStep = () => {
		if (activeStep < 3) setActiveStep(activeStep + 1);
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	};
	const previousStep = () => {
		if (activeStep > 0) setActiveStep(activeStep - 1);
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	};
	const handlePublication = async () => {
		const {
			price,
			amountPesos,
			batch,
			isPartialAllowed,
			minimunPartialAmount,
		} = newBuyOrder;
		if (price && amountPesos && batch) {
			setAwaitResponse(true);
			asyncHandler.publishNewOrder(
				{
					type: ListingType.PURCHASE,
					unitPrice: price,
					minimumDenominationToReceive: amountPesos.name,
					isArchived: false,
					isDiscarded: false,
					advanceMinimumRequired: orderAmr,
					listingBatches: [
						{
							amount: batch.amount,
							billColor: batch.version.color,
							billDenomination: `${batch.denomination.name}-${batch.denomination.value}`,
							billStatus: batch.status.split('-')[1],
							billVersion: `${batch.version.name}/${batch.version.description}`,
						},
					],
					listingShifts: await convertAppointmentTime(
						selectedAvailableAppointments,
					),
					expirationDate: moment(expirationDate).toDate(),
					isPartialAllowed,
					minimunPartialAmount,
					currency,
				},
				setPublishedPublication,
				previousStep,
			);
		}
	};
	useEffect(() => {
		publishedPublication && setActiveStep(4);
	}, [publishedPublication]);
	return (
		<Box sx={{ marginTop: '25px' }}>
			{publishedPublication && <ApprovedPublication />}
			{!publishedPublication && <StepperComponent activeStep={activeStep} />}
			{activeStep === 0 && <FirstStepComponent nextStep={nextStep} />}
			{activeStep === 1 && (
				<SecondStepComponent nextStep={nextStep} previousStep={previousStep} />
			)}
			{activeStep === 2 && (
				<ThirdStepComponent nextStep={nextStep} previousStep={previousStep} />
			)}
			{activeStep === 3 && (
				<FourthStepComponent
					awaitResponse={awaitResponse}
					previousStep={previousStep}
					nextStep={handlePublication}
				/>
			)}
		</Box>
	);
};

export default NewPublication;
