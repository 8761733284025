import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Tooltip } from '@mui/material';

interface CommonTooltipProps {
	title: string;
}

export const UserTooltip = ({ title }: CommonTooltipProps) => {
	return (
		<Tooltip
			disableFocusListener
			enterTouchDelay={0}
			leaveTouchDelay={15000}
			placement="bottom-end"
			slotProps={{
				tooltip: {
					style: {
						backgroundColor: '#fff',
						color: 'black',
						marginTop: '-3px',
						padding: '16px',
						borderRadius: '2px',
					},
				},
			}}
			title={title}
		>
			<AccountBoxIcon fontSize="large" />
		</Tooltip>
	);
};
