import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './home.styles.css';
import { GreenButton } from '../../components/green-button.component';
import { BlueButton } from '../../components/blue-button.component';
import { asyncHandler } from '../../utils/async-handler';
import { useNavigate } from 'react-router-dom';
import { getUserEmailFromToken } from '../../utils/user/user';

const Home = () => {
	const navigate = useNavigate();
	const [userEmail, setUserEmail] = useState('');

	const formatUserName = (email: string) => {
		const namePart = email.split('@')[0];
		return namePart.charAt(0) + namePart.slice(1);
	};

	useEffect(() => {
		const notificationToken = localStorage.getItem('notificationToken');
		if (notificationToken) {
			asyncHandler.syncNotificationToken(notificationToken);
		}
	}, []);

	useEffect(() => {
		const token = localStorage.getItem('firebaseToken');
		if (token) {
			setUserEmail(getUserEmailFromToken(token));
		}
		if (!token) {
			navigate('/login');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box className="home-container">
			<Box className="home">
				<img src={'/cambiapp_logo_gray.png'} alt="Logo" width={150} />
				<Typography
					color={'#2e2e2e'}
					align="center"
					variant="h6"
					noWrap
					sx={{ fontWeight: 400 }}
				>
					{`Bienvenido ${formatUserName(userEmail)}`}
				</Typography>
				<Box className="buttons-container">
					<BlueButton className="button" href="/buy">
						Comprar {'\n'}
						divisas
					</BlueButton>
					<GreenButton className="button" href="/sell">
						Vender {'\n'}
						divisas
					</GreenButton>
				</Box>
			</Box>
		</Box>
	);
};

export default Home;
