import { InfoOutlined } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';

interface CommonTooltipProps {
	title: string;
}

export const InformationTooltip = ({ title }: CommonTooltipProps) => {
	return (
		<Tooltip
			disableFocusListener
			enterTouchDelay={0}
			leaveTouchDelay={15000}
			slotProps={{
				tooltip: { style: { backgroundColor: 'rgba(95, 95, 95, 1)' } },
			}}
			title={
				<Typography sx={{ fontStyle: 'italic', fontSize: 12 }}>
					{title}
				</Typography>
			}
		>
			<InfoOutlined
				color={'disabled'}
				sx={{ verticalAlign: 'middle', fontSize: '32px' }}
			/>
		</Tooltip>
	);
};
