import { Tooltip, Typography } from '@mui/material';

interface CommonTooltipProps {
	title: string;
	children: any;
}

export const CommonTooltip = ({ title, children }: CommonTooltipProps) => {
	return (
		<Tooltip
			disableFocusListener
			enterTouchDelay={0}
			leaveTouchDelay={15000}
			slotProps={{
				tooltip: { style: { backgroundColor: 'rgba(95, 95, 95, 1)' } },
			}}
			title={
				<Typography sx={{ fontStyle: 'italic', fontSize: 12 }}>
					{title}
				</Typography>
			}
		>
			{children}
		</Tooltip>
	);
};
