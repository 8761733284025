import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Typography,
} from '@mui/material';

interface DeleteButtonProps {
	isHideDialogOpen: boolean;
	setHideDialogOpen: (newState: boolean) => void;
	buttonAction: () => void;
	buttonCallback: () => void;
}

export const DeleteButton = ({
	isHideDialogOpen,
	setHideDialogOpen,
	buttonAction,
	buttonCallback,
}: DeleteButtonProps) => {
	return (
		<>
			<Dialog
				open={isHideDialogOpen}
				onClose={() => setHideDialogOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					¿Esta seguro que desea ocultar las publicaciones?
				</DialogTitle>
				<DialogActions>
					<Button onClick={() => setHideDialogOpen(false)} color="error">
						NO
					</Button>
					<Button onClick={buttonCallback} autoFocus>
						SI
					</Button>
				</DialogActions>
			</Dialog>
			<Box
				sx={{
					width: '100%',
					padding: '10px 0px',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Button
					variant="contained"
					color="error"
					sx={{ padding: '2.5px 5px' }}
					onClick={buttonAction}
				>
					<Typography fontSize={12} fontWeight={400}>
						Ocultar registros
					</Typography>
				</Button>
			</Box>
		</>
	);
};
