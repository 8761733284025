import { useNavigate, useParams } from 'react-router-dom';
import { asyncHandler } from '../../utils/async-handler';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Spinner } from '../../components/spinner/spinner.component';
import {
	CambiappContext,
	CambiappContextType,
	IAvailableAppointments,
} from '../../context/cambiapp.context';
import {
	Box,
	Button,
	InputAdornment,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import { currenciesFormater } from '../../utils/currencies/currencies-formater';
import { StyledTableCell } from '../../components/new-order-steps/buy';
import moment from 'moment';
import { convertTime } from '../../utils/date';
import axios, { AxiosError } from 'axios';
import Countdown from 'react-countdown';
import { orderShifts } from '../../utils/shift';

const PartialBuyDetailPage = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { statuses, currency } = useContext(
		CambiappContext,
	) as CambiappContextType;
	const [details, setDetails] = useState<any>();
	const [publication, setPublication] = useState<any>();
	const [nextStep, setNextStep] = useState(false);
	const [amountToBuy, setAmountToBuy] = useState();
	const [partialAmountToBuy, setPartialAmountToBuy] = useState();
	const [isRequestSended, setRequestSendState] = useState(false);
	const [selectedDate, setSelectedDate] = useState<{
		date: string;
		shift: string;
	}>();
	const handleRefresh = () => {
		window.location.reload();
	};
	let toDay = '';

	const handleConfirm = async () => {
		setRequestSendState(true);
		if (selectedDate) {
			try {
				const result = await asyncHandler.handlePartialConfirmation(
					parseInt(id!),
					partialAmountToBuy!,
					selectedDate.date,
					selectedDate.shift,
				);
				if (result?.status === 200) {
					alert('Confirmacion exitosa');
					navigate('/buy');
				}
			} catch (error) {
				setRequestSendState(false);
				if (axios.isAxiosError(error)) {
					const axiosError = error as AxiosError; // Indicar a TypeScript que es un AxiosError
					if (axiosError.response && axiosError.response.status === 409) {
						alert(
							'Ese turno ya fue reservado por otro usuario, elija otro por favor.',
						);
						handleRefresh();
					} else {
						console.error('Error:', axiosError);
					}
				} else alert('Ocurrio un error. Vuelva intentar en unos segundos');
			}
		}
	};

	useEffect(() => {
		async function fetchPublication() {
			const response = await asyncHandler.fetchPublicatioById(parseInt(id!));
			setPublication(response);
		}
		fetchPublication();
		if (!statuses.isLoading && statuses.data?.length === 0) statuses.refetch();
	}, [id, statuses, statuses.data]);

	const expirationTime = useMemo(() => {
		if (details) {
			if (details.expirationDate) {
				const expirationDate = moment(details.expirationDate);
				return expirationDate.toDate();
			} else if (details.listingShifts) {
				const date = details.listingShifts.sort((a: any, b: any) =>
					moment(a.date.split('T')[0])
						.add(a.shift.split(':')[0], 'hours')
						.add(a.shift.split(':')[1], 'minutes')
						.isAfter(
							moment(b.date.split('T')[0])
								.add(b.shift.split(':')[0], 'hours')
								.add(b.shift.split(':')[1], 'minutes'),
						)
						? 1
						: -1,
				)[details.listingShifts.length - 1];
				const res = moment(date.date.split('T')[0])
					.add(date.shift.split(':')[0], 'hours')
					.add(date.shift.split(':')[1], 'minutes')
					.subtract(details.advanceMinimumRequired.split(':')[0], 'hours')
					.subtract(
						parseInt(details.advanceMinimumRequired.split(':')[1]) + 1,
						'minutes',
					);
				return res.toDate();
			}
		} else {
			return '...';
		}
	}, [details]);

	useEffect(() => {
		const fetchDetails = async () => {
			const response = await asyncHandler.fetchPublicatioById(parseInt(id!));
			setDetails(response);
		};
		fetchDetails();
	}, [id]);

	useEffect(() => {
		publication?.listingShifts && orderShifts(publication?.listingShifts);
	}, [publication]);

	const isToday = (
		date: string,
		shift: string,
		advanceMinimumRequired: string,
	) => {
		if (moment(date.split('T')[0]).isSame(moment(), 'day')) {
			if (
				moment(shift, 'HH:mm')
					.subtract(advanceMinimumRequired.split(':')[0], 'hours')
					.subtract(
						parseInt(advanceMinimumRequired.split(':')[1]) + 1,
						'minutes',
					)
					.isAfter(moment().add(3, 'hours'))
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	};

	const handlePartialAmountChange = (e: any) => {
		setAmountToBuy(e.target.value);
	};

	return (
		<Box
			sx={{
				backgroundColor: 'background.paper',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				minHeight: 'calc(100vh - 55px)',
			}}
		>
			{(!publication || !publication.isPartialAllowed) && <Spinner />}
			{!partialAmountToBuy && publication && publication.isPartialAllowed && (
				<Box width={'80%'} margin={'50px auto'}>
					<Typography fontSize={14} textAlign="center">
						Elegí la cantidad de {currency} que querés comprar. Debe ser igual o
						mayor a{' '}
						<span style={{ color: '#ffff00' }}>
							{currency} {currenciesFormater(publication.minimunPartialAmount)}
						</span>
						, menor a{' '}
						<span style={{ color: '#ffff00' }}>
							{currency} {currenciesFormater(publication.totalAmount)}
						</span>{' '}
						y multiplo de{' '}
						<span style={{ color: '#ffff00' }}>
							{publication.listingBatches[0].billDenomination.split('-')[1]}
						</span>
					</Typography>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							marginTop: '40px',
						}}
					>
						<TextField
							variant="standard"
							sx={{
								width: '100%',
								maxWidth: '350px',
								backgroundColor: '#000000',
								padding: '10px',
								borderRadius: '5px',
								color: 'white',
							}}
							onChange={(e) => handlePartialAmountChange(e)}
							placeholder="0,00"
							value={amountToBuy || ''}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">{currency}</InputAdornment>
								),
								disableUnderline: true,
							}}
						/>
						<Button
							variant="contained"
							sx={{
								marginTop: '40px',
								width: '100%',
								maxWidth: '350px',
								color: '#ffffff',
							}}
							style={{ backgroundColor: '#3C8846' }}
							onClick={() =>
								amountToBuy &&
								amountToBuy !== 0 &&
								amountToBuy >= 100 &&
								amountToBuy >= publication.minimunPartialAmount &&
								amountToBuy < parseInt(publication.totalAmount.toString()) &&
								amountToBuy %
									publication.listingBatches[0].billDenomination.split(
										'-',
									)[1] ===
									0
									? setPartialAmountToBuy(amountToBuy)
									: alert(
											`La cantidad ingresada no es válida. Debe ser mayor o igual a ${
												publication.minimunPartialAmount
											}, menor a ${publication.totalAmount} y múltiplo de ${
												publication.listingBatches[0].billDenomination.split(
													'-',
												)[1]
											}`,
									  )
							}
						>
							GUARDAR
						</Button>
					</Box>
				</Box>
			)}
			{partialAmountToBuy &&
				!nextStep &&
				publication &&
				publication.isPartialAllowed && (
					<Box width={'80%'} margin={'50px auto'}>
						<Typography fontSize={14} textAlign="center">
							Elegí el día y la hora para concretar tu compra en la Casa, entre
							las opciones disponibles (fondo azul)
						</Typography>
						{publication &&
							publication.listingShifts.length > 0 &&
							publication.listingShifts.sort((a: any, b: any) =>
								a.date.split('T')[0].localeCompare(b.date.split('T')[0]),
							) &&
							publication.listingShifts.map(
								(
									{ date, shift, isAvailable }: IAvailableAppointments,
									index: number,
								) => {
									if (
										toDay !== date.split('T')[0] &&
										moment(date.split('T')[0])
											.add(shift.split(':')[0], 'hours')
											.add(shift.split(':')[1], 'minutes')
											.subtract(3, 'hours')
											.subtract(
												publication.advanceMinimumRequired.split(':')[0],
												'hours',
											)
											.subtract(
												parseInt(
													publication.advanceMinimumRequired.split(':')[1],
												) + 1,
												'minutes',
											)
											.isAfter(moment()) &&
										isAvailable
									) {
										toDay = date.split('T')[0];
										return (
											<Box
												key={index}
												sx={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
													margin: '20px 0 0',
												}}
											>
												<Typography>
													{moment(date)
														.add(3, 'hours')
														.format('dddd D MMMM')
														.toUpperCase()}
												</Typography>
												<Box
													sx={{
														display: 'flex',
														flexWrap: 'wrap',
														justifyContent: 'center',
													}}
												>
													{publication.listingShifts.map(
														(publicationDateDetails: any) => {
															if (
																date.split('T')[0] ===
																	publicationDateDetails.date.split('T')[0] &&
																isToday(
																	date,
																	publicationDateDetails.shift,
																	publication.advanceMinimumRequired,
																) &&
																publicationDateDetails.isAvailable
															) {
																return (
																	<Typography
																		sx={{
																			display: 'inline-block',
																			bgcolor:
																				selectedDate &&
																				selectedDate.date ===
																					date.split('T')[0] &&
																				selectedDate.shift ===
																					publicationDateDetails.shift
																					? '#3C8846'
																					: '#405F8E',
																			margin: '2px',
																			padding: '1px',
																		}}
																		onClick={() =>
																			setSelectedDate({
																				date: date.split('T')[0],
																				shift: publicationDateDetails.shift,
																			})
																		}
																		key={`${date.split('T')[0]}${
																			publicationDateDetails.shift
																		}`}
																	>
																		{convertTime(
																			publicationDateDetails.shift,
																			'ARG',
																		)}
																	</Typography>
																);
															} else return <></>;
														},
													)}
												</Box>
											</Box>
										);
									} else return <></>;
								},
							)}
						<Box
							sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
						>
							<Button
								variant="contained"
								sx={{
									marginTop: '40px',
									width: '80%',
									maxWidth: '300px',
									color: '#ffffff',
								}}
								style={{ backgroundColor: '#3C8846' }}
								onClick={() => (selectedDate ? setNextStep(true) : null)}
							>
								GUARDAR
							</Button>
						</Box>
					</Box>
				)}
			{partialAmountToBuy &&
				publication &&
				publication.isPartialAllowed &&
				nextStep && (
					<>
						<h2 style={{ color: '#4fc3f7' }}>Estas Comprando:</h2>
						<TableContainer component={Paper} sx={{ marign: '8px' }}>
							<Table>
								<TableHead>
									<TableRow>
										<StyledTableCell sx={{ textAlign: 'center' }}>
											Total ({currency})
										</StyledTableCell>
										<StyledTableCell sx={{ textAlign: 'center' }}>
											Denominación
										</StyledTableCell>
										<StyledTableCell sx={{ textAlign: 'center' }}>
											Versión
										</StyledTableCell>
										<StyledTableCell sx={{ textAlign: 'center' }}>
											Estado
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{publication.listingBatches?.map((batch: any) => {
										return (
											<TableRow key={batch.id}>
												<StyledTableCell sx={{ textAlign: 'center' }}>
													{currenciesFormater(partialAmountToBuy)}
												</StyledTableCell>
												<StyledTableCell sx={{ textAlign: 'center' }}>
													{batch.billDenomination.split('-')[0]}
												</StyledTableCell>
												<StyledTableCell
													sx={{
														textAlign: 'center',
														color: batch.billColor,
													}}
												>
													{batch.billVersion.split('/')[0]}
													<br />
													{batch.billVersion.split('/')[1]}
												</StyledTableCell>
												<StyledTableCell sx={{ textAlign: 'center' }}>
													{statuses.data &&
														statuses.data.indexOf(batch.billStatus) + 1}
													.{batch.billStatus}
												</StyledTableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<Box
							sx={{
								display: 'grid',
								gridTemplateColumns: 'repeat(4, 1fr)',
								gridTemplateRows: 'repeat(4, 1fr)',
								gridColumnGap: '20px',
								gridRowGap: '10px',
								margin: 0,
								padding: '20px 20px',
							}}
						>
							<Typography
								sx={{
									width: 'max-content',
									height: 'max-content',
									gridArea: '1 / 1 / 2 / 3',
								}}
							>
								Cantidad total
							</Typography>
							<Box
								sx={{
									borderRadius: '5px',
									padding: '5px 10px',
									width: '100%',
									height: 'max-content',
									gridArea: '1 / 3 / 2 / 5',
									bgcolor: '#000000',
								}}
							>
								<Typography
									sx={{
										width: 'auto',
										height: 'max-content',
										textAlign: 'right',
										color: '#ffff00',
									}}
								>
									{currency} {currenciesFormater(partialAmountToBuy)}
								</Typography>
							</Box>
							<Typography
								sx={{
									width: 'max-content',
									height: 'max-content',
									gridArea: '2 / 1 / 3 / 3',
								}}
							>
								Precio unitario
							</Typography>
							<Box
								sx={{
									borderRadius: '5px',
									padding: '5px 10px',
									width: '100%',
									height: 'max-content',
									gridArea: '2 / 3 / 3 / 5',
									bgcolor: '#000000',
								}}
							>
								<Typography
									sx={{
										width: 'auto',
										height: 'max-content',
										textAlign: 'right',
									}}
								>
									{currenciesFormater(publication.unitPrice, 'ARS')[0]}{' '}
									{currenciesFormater(publication.unitPrice, 'ARS')[1]}
								</Typography>
							</Box>
							<Typography
								sx={{
									width: 'max-content',
									height: 'max-content',
									gridArea: '3 / 1 / 4 / 3',
								}}
							>
								Total
							</Typography>
							<Box
								sx={{
									borderRadius: '5px',
									padding: '5px 10px',
									width: '100%',
									height: 'max-content',
									gridArea: '3 / 3 / 4 / 5',
									bgcolor: '#000000',
								}}
							>
								<Typography
									sx={{
										width: 'auto',
										height: 'max-content',
										textAlign: 'right',
										color: '#ffff00',
									}}
								>
									ARS{' '}
									{currenciesFormater(
										publication.unitPrice * partialAmountToBuy,
									)}
								</Typography>
							</Box>
							<Typography
								fontSize={15}
								style={{
									gridArea: '4 / 1 / 5 / 4',
									maxWidth: '260px',
								}}
							>
								Denominación mínima de billetes pesos a entregar al vendedor:
							</Typography>
							<Typography
								fontSize={14}
								sx={{
									margin: 'auto',
									marginLeft: '-25px',
									bgcolor: '#000000',
									color: 'white',
									padding: '8px 10px',
									gridArea: '4 / 4 / 5 / 5',
									borderRadius: '5px',
								}}
							>
								{publication.minimumDenominationToReceive}
							</Typography>
						</Box>
						<Box
							sx={{
								display: 'grid',
								gridTemplateColumns: 'repeat(2, 1fr)',
								gridTemplateRows: 'repeat(2, 1fr)',
								gridColumnGap: '20px',
								gridRowGap: '10px',
							}}
						>
							<Typography>Fecha de transacción</Typography>
							<Typography
								sx={{
									width: 'auto',
									height: 'max-content',
									textAlign: 'center',
									borderRadius: '5px',
									padding: '5px 10px',
									bgcolor: '#000000',
								}}
							>
								{selectedDate && moment(selectedDate.date).format('DD/MM/YYYY')}
							</Typography>
							<Typography>Hora de transacción</Typography>
							<Typography
								sx={{
									width: 'auto',
									height: 'max-content',
									textAlign: 'center',
									borderRadius: '5px',
									padding: '5px 10px',
									bgcolor: '#000000',
								}}
							>
								{selectedDate && convertTime(selectedDate.shift, 'ARG')} hs
							</Typography>
							<Typography sx={{ textAlign: 'right' }}>Vence en:</Typography>
							{expirationTime && (
								<>
									<Countdown
										date={expirationTime}
										zeroPadTime={2}
										daysInHours
										renderer={({ formatted }) => (
											<Typography sx={{ textAlign: 'left' }}>
												{formatted.hours}:{formatted.minutes}:
												{formatted.seconds}
											</Typography>
										)}
									/>
								</>
							)}
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								backgroundColor: 'pink',
								width: '80%',
								margin: '25px 0',
								padding: '25px',
								color: 'black',
							}}
						>
							<Typography fontSize={12} textAlign={'justify'}>
								Aceptas la oferta del vendedor, y te estás comprometiendo a
								presentarte en la Casa a concretar la compra, en la fecha y la
								hora establecida, y en las condiciones de arriba expuestas, sin
								excepciones.
							</Typography>
							<br />
							<Typography fontSize={14} fontWeight={600}>
								¿Estás seguro de confirmar la compra?
							</Typography>
							<br />
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-evenly',
								}}
							>
								<Button
									variant="contained"
									color="success"
									sx={{ color: 'white' }}
									onClick={handleConfirm}
									disabled={isRequestSended}
								>
									Si
								</Button>
								<Button
									variant="contained"
									color="error"
									sx={{ color: 'white' }}
									onClick={() => navigate('/buy')}
								>
									No
								</Button>
							</Box>
						</Box>
					</>
				)}
		</Box>
	);
};

export default PartialBuyDetailPage;
