import { useContext, useEffect } from 'react';
import {
	CambiappContext,
	CambiappContextType,
} from '../../../context/cambiapp.context';
import { TableCell, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import {
	currenciesFormater,
	decimalFormater,
} from '../../../utils/currencies/currencies-formater';

interface ITableBodyAborted {
	children: any;
}

export const TableBodyAborted = ({ children }: ITableBodyAborted) => {
	const { statuses } = useContext(CambiappContext) as CambiappContextType;

	useEffect(() => {
		if (!statuses.isLoading && statuses.data?.length === 0) statuses.refetch();
	}, [statuses]);

	return (
		<TableRow>
			<TableCell>
				<Typography>{children.listingBatches[0]?.amount}</Typography>
				<Typography>{children.listingBatches[1]?.amount}</Typography>
			</TableCell>
			<TableCell>
				<Typography>
					{children.listingBatches[0]?.billDenomination?.split('-')[0]}
				</Typography>
				<Typography>
					{children.listingBatches[1]?.billDenomination?.split('-')[0]}
				</Typography>
			</TableCell>
			<TableCell>
				<Typography
					sx={{
						color: children.listingBatches[0]?.billColor,
						textAlign: 'center',
					}}
				>
					{children.listingBatches[0]?.billVersion.split('/')[0]}
				</Typography>
				<Typography
					sx={{
						color: children.listingBatches[1]?.billColor,
						textAlign: 'center',
					}}
				>
					{children.listingBatches[1]?.billVersion.split('/')[0]}
				</Typography>
			</TableCell>
			<TableCell>
				<Typography sx={{ textAlign: 'center' }}>
					{statuses.data &&
					statuses.data.indexOf(children.listingBatches[0]?.billStatus) >= 0
						? statuses.data.indexOf(children.listingBatches[0]?.billStatus) + 1
						: null}
				</Typography>
				<Typography sx={{ textAlign: 'center' }}>
					{statuses.data &&
					statuses.data.indexOf(children.listingBatches[1]?.billStatus) >= 0
						? statuses.data.indexOf(children.listingBatches[1]?.billStatus) + 1
						: null}
				</Typography>
			</TableCell>
			<TableCell>
				<Typography>{currenciesFormater(children.totalAmount)}</Typography>
			</TableCell>
			<TableCell>{currenciesFormater(children.unitPrice, 'ARS')[1]}</TableCell>
			<TableCell>
				{decimalFormater(children.unitPrice * children.totalAmount)}
			</TableCell>
			<TableCell>
				{moment(children.creationAt).format('DD/MM/YYYY HH:mm')}
			</TableCell>
			<TableCell>{children.createdBy}</TableCell>
			<TableCell
				sx={{
					backgroundColor: children.type === 'VENTA' ? '#81c784' : '#4fc3f7',
				}}
			>
				{children.type}
			</TableCell>
			<TableCell>
				{children.confirmationDate &&
					moment(children.confirmationDate).format('DD/MM/YYYY HH:mm')}
			</TableCell>
			<TableCell>{children.appointment?.nickname}</TableCell>
			<TableCell
				sx={{
					backgroundColor: children.appointment?.nickname
						? children.type === 'COMPRA'
							? '#81c784'
							: '#4fc3f7'
						: '',
				}}
			>
				{children.appointment?.nickname
					? children.createdBy === 'Yo'
						? children.type === 'VENTA'
							? 'Compró'
							: 'Vendió'
						: children.type === 'VENTA'
						? 'Compré'
						: 'Vendí'
					: ''}
			</TableCell>
			<TableCell>
				{children.transactionId
					? children.transactionId.slice(children.transactionId.length - 6)
					: ''}
			</TableCell>
			<TableCell>
				{children.appointment?.date &&
					moment(children.appointment.date)
						.add(children.appointment.reservationHour.split(':')[0], 'hours')
						.add(children.appointment.reservationHour.split(':')[1], 'minutes')
						.format('DD/MM/YYYY HH:mm')}
			</TableCell>
			<TableCell>
				{children.deletedAt &&
					moment(children.deletedAt).format('DD/MM/YYYY HH:mm')}
				{children.abortedAt &&
					moment(children.abortedAt).format('DD/MM/YYYY HH:mm')}
			</TableCell>
			<TableCell>
				{children.operatorAbortion
					? children.operatorAbortion
					: children.status === 'CREADO'
					? 'Yo'
					: children.deletedOperator}
			</TableCell>
			<TableCell
				sx={{
					backgroundColor: children.abortedAt ? '#F44336' : '',
				}}
			>
				{children.deletedDecision && children.deletedDecision}
				{children.decisionAbortion && children.decisionAbortion}
			</TableCell>
		</TableRow>
	);
};
