import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	CssBaseline,
	Fab,
	List,
	Typography,
} from '@mui/material';
import { useEffect, useMemo, useContext, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { Publication } from '../../components/publication.component';
import { useNavigate } from 'react-router-dom';
import {
	CambiappContext,
	CambiappContextType,
} from '../../context/cambiapp.context';
import theme from '../../theme/mui-theme';
import { ConfirmedPublication } from '../../components/confirmed-publication.component';
import Table from '../../components/table/table-component';
import { completedPublicationsTableHeader } from '../../components/table/table-config';
import { ExpiredTableComponent } from '../../components/table/expired-table/expired-table.component';
import { AbortedTableComponent } from '../../components/table/aborted-table/aborted-table.component';
import { Spinner } from '../../components/spinner/spinner.component';
import { asyncHandler } from '../../utils/async-handler';
import { DeleteButton } from '../../components/button/delete-pubs-button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Currency } from '../../interfaces/publication.interface';

const BuyingDashboard = () => {
	const navigate = useNavigate();
	const [loader, setLoader] = useState(true);
	const [isCompletedDialogOpen, setCompletedDialogOpen] = useState(false);
	const [isExpiredDialogOpen, setExpiredDialogOpen] = useState(false);
	const [pubsToDelete, setPubsToDelete] = useState<number[]>();
	const [currencyColor, setCurrencyColor] = useState('#48887B');
	const {
		currency,
		setCurrency,
		currencies,
		buyPublications,
		confirmedBuyPublications,
		completedBuyPublications,
		expiredBuyPublications,
		abortedBuyPublications,
	} = useContext(CambiappContext) as CambiappContextType;

	const handleChange = (event: SelectChangeEvent) => {
		const currency = event.target.value as Currency;
		setCurrency(currency);
		switch(currency) {
			case Currency.USD:
				setCurrencyColor('#48887B');
				break;
			case Currency.EUR:
				setCurrencyColor('#384881');
				break;
			case Currency.BRL:
				setCurrencyColor('#DA7739');
				break;
		}
	};

	const amountOfPublications = useMemo(
		() =>
			buyPublications.isLoading
				? '...'
				: buyPublications.isSuccess
				? buyPublications.data?.length
				: 0,
		[buyPublications],
	);

	const amountOfConfirmedPublications = useMemo(
		() =>
			confirmedBuyPublications.isLoading
				? '...'
				: confirmedBuyPublications.isSuccess
				? confirmedBuyPublications.data?.length
				: 0,
		[confirmedBuyPublications],
	);

	const amountOfCompletedPublications = useMemo(
		() =>
			completedBuyPublications.isLoading
				? '...'
				: completedBuyPublications.isSuccess
				? completedBuyPublications.data?.length
				: 0,
		[completedBuyPublications],
	);

	useEffect(() => {
		if (!buyPublications.data) buyPublications.refetch();
		if (buyPublications.data?.length === 0 && !buyPublications.isLoading)
			buyPublications.refetch();
		if (
			!confirmedBuyPublications.isLoading &&
			confirmedBuyPublications.data?.length === 0
		)
			confirmedBuyPublications.refetch();
		if (
			!completedBuyPublications.isLoading &&
			completedBuyPublications.data?.length === 0
		)
			completedBuyPublications.refetch();
		if (
			!buyPublications.isLoading &&
			!confirmedBuyPublications.isLoading &&
			!completedBuyPublications.isLoading &&
			!expiredBuyPublications.isLoading &&
			!abortedBuyPublications.isLoading
		)
			setLoader(false);
	}, [
		buyPublications,
		confirmedBuyPublications,
		completedBuyPublications,
		expiredBuyPublications,
		abortedBuyPublications,
	]);

	useEffect(() => {
		setLoader(true);
		buyPublications.refetch();
		confirmedBuyPublications.refetch();
		completedBuyPublications.refetch();
		expiredBuyPublications.refetch();
		abortedBuyPublications.refetch();

		setLoader(false);
	}, [currency]);

	theme.palette.background.default = '#d2d2d2';

	const handleDialogOfCompletedPublications = () => {
		setPubsToDelete(completedBuyPublications.data?.map((pub) => pub.id));
		setCompletedDialogOpen(true);
	};
	const handleDialogOfExpiredPublications = () => {
		setPubsToDelete([
			...expiredBuyPublications.data?.map((pub) => pub.id)!,
			...abortedBuyPublications.data?.map((pub) => pub.id)!,
		]);
		setExpiredDialogOpen(true);
	};
	const handleHideOfCompletedPublications = () => {
		asyncHandler.hidePublications(pubsToDelete!, () =>
			setCompletedDialogOpen(false),
		);
	};
	const handleHideOfExpiredPublications = () => {
		asyncHandler.hidePublications(pubsToDelete!, () =>
			setExpiredDialogOpen(false),
		);
	};

	return (
		<Box
			sx={{ backgroundColor: '#F1F1F2', height: '100%', minHeight: '100vh' }}
		>
			{loader && (
				<Box
					sx={{
						position: 'absolute',
						top: '57px',
						backgroundColor: '#00000033',
						width: '100vw',
						height: 'calc(100vh - 57px)',
						zIndex: 2000,
					}}
				>
					<Spinner />
				</Box>
			)}
			<CssBaseline enableColorScheme />
			<Box
				sx={{
					backgroundColor: currencyColor,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					padding: '10px 15px',
				}}
			>
				<Box sx={{ flex: 1 }} />
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flex: 2,
					}}
				>
					<Typography
						fontWeight={600}
						fontSize={20}
						sx={{ marginRight: '10px' }}
					>
						{currency === Currency.USD && 'Dólares'}
						{currency === Currency.EUR && 'Euros'}
						{currency === Currency.BRL && 'Reales'}
					</Typography>
					<img
						src={
							currency === Currency.USD
								? 'usa-flag.png'
								: currency === Currency.EUR
								? 'euro-flag.png'
								: 'real-flag.png'
						}
						alt="Logo"
						style={{ border: '1px solid white', borderRadius: 100 }}
						width={23}
					/>
				</Box>

				<FormControl
					sx={{
						flex: 1,
						maxWidth: 110,
						backgroundColor: '#CCCCCC',
						borderRadius: '5px',
					}}
					size="small"
				>
					<Select
						id="demo-simple-select"
						value={currency}
						onChange={handleChange}
						sx={{
							'color': 'black',
							'.MuiOutlinedInput-notchedOutline': {
								borderColor: '#191919',
							},
							'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
								borderColor: '#191919',
							},
							'&:hover .MuiOutlinedInput-notchedOutline': {
								borderColor: '#191919',
							},
							'.MuiSvgIcon-root ': {
								fill: 'black !important',
							},
						}}
					>
						<MenuItem value={Currency.USD}>USD</MenuItem>
						<MenuItem value={Currency.EUR}>EUR</MenuItem>
						<MenuItem value={Currency.BRL}>BRL</MenuItem>
					</Select>
				</FormControl>
			</Box>
			<Accordion defaultExpanded>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
					sx={{ backgroundColor: '#CCCCCC', color: 'black' }}
				>
					<Typography fontWeight={400} fontSize={16}>
						Publicaciones vigentes{' '}
						<span style={{ color: '#737373' }}>{amountOfPublications}</span>
					</Typography>
				</AccordionSummary>
				{buyPublications.data && buyPublications.data.length > 0 && (
					<AccordionDetails
						sx={{ backgroundColor: '#d2d2d2', padding: '0px 5px' }}
					>
						<List
							sx={{
								display: 'flex',
								flexDirection: 'column',
								rowGap: '10px',
							}}
						>
							{buyPublications.data.map(
								(publication: { [key: string]: any }) => {
									return (
										<Publication key={publication.id} currency={currency}>
											{publication}
										</Publication>
									);
								},
							)}
						</List>
					</AccordionDetails>
				)}
			</Accordion>
			<Accordion defaultExpanded>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
					sx={{ backgroundColor: '#CCCCCC', color: 'black' }}
				>
					<Typography fontWeight={400} fontSize={16}>
						Operaciones confirmadas{' '}
						<span style={{ color: '#737373' }}>
							{amountOfConfirmedPublications}
						</span>
					</Typography>
				</AccordionSummary>
				{confirmedBuyPublications.data &&
					confirmedBuyPublications.data.length > 0 && (
						<AccordionDetails
							sx={{ backgroundColor: '#d2d2d2', padding: '0px 5px' }}
						>
							<List
								sx={{
									display: 'flex',
									flexDirection: 'column',
									rowGap: '10px',
								}}
							>
								{confirmedBuyPublications.data.map(
									(publication: { [key: string]: any }) => {
										return (
											<ConfirmedPublication
												key={publication.id}
												currency={currency}
											>
												{publication}
											</ConfirmedPublication>
										);
									},
								)}
							</List>
						</AccordionDetails>
					)}
			</Accordion>
			<Accordion sx={{ backgroundColor: '#CCCCCC', color: 'black' }}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
					sx={{ backgroundColor: '#CCCCCC', color: 'black' }}
				>
					<Typography fontWeight={400} fontSize={16}>
						Operaciones concretadas{' '}
						<span style={{ color: '#737373' }}>
							{amountOfCompletedPublications}
						</span>
					</Typography>
				</AccordionSummary>
				{completedBuyPublications.data &&
					completedBuyPublications.data.length > 0 && (
						<DeleteButton
							isHideDialogOpen={isCompletedDialogOpen}
							setHideDialogOpen={setCompletedDialogOpen}
							buttonAction={handleDialogOfCompletedPublications}
							buttonCallback={handleHideOfCompletedPublications}
						/>
					)}
				{completedBuyPublications.data &&
					completedBuyPublications.data.length > 0 && (
						<Table
							tableHeader={completedPublicationsTableHeader}
							tableBody={completedBuyPublications.data!}
						/>
					)}
			</Accordion>
			<Accordion sx={{ backgroundColor: '#CCCCCC', color: 'black' }}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
					sx={{ backgroundColor: '#CCCCCC', color: 'black' }}
				>
					<Typography fontWeight={400} fontSize={16}>
						Publicaciones y Op. Archivadas{' '}
						<span style={{ color: '#737373' }}>
							{(expiredBuyPublications.data?.length || 0) +
								(abortedBuyPublications.data?.length || 0)}
						</span>
					</Typography>
				</AccordionSummary>
				{((expiredBuyPublications.data &&
					expiredBuyPublications.data.length > 0) ||
					(abortedBuyPublications.data &&
						abortedBuyPublications.data.length > 0)) && (
					<DeleteButton
						isHideDialogOpen={isExpiredDialogOpen}
						setHideDialogOpen={setExpiredDialogOpen}
						buttonAction={handleDialogOfExpiredPublications}
						buttonCallback={handleHideOfExpiredPublications}
					/>
				)}
				<Typography
					align="center"
					sx={{ backgroundColor: '#D4ECF9', color: 'black' }}
				>
					Publicaciones vencidas/caducadas
				</Typography>
				{expiredBuyPublications.data && (
					<ExpiredTableComponent
						expiredPublications={expiredBuyPublications.data}
					/>
				)}
				<Typography
					align="center"
					sx={{ backgroundColor: '#D4ECF9', color: 'black' }}
				>
					Publicaciones abortadas/eliminadas
				</Typography>
				{abortedBuyPublications.data && (
					<AbortedTableComponent
						abortedPublications={abortedBuyPublications.data}
					/>
				)}
			</Accordion>
			<Fab
				variant="extended"
				size="medium"
				aria-label="add"
				sx={{
					position: 'fixed',
					bottom: '5%',
					left: '50%',
					transform: 'translateX(-50%)',
					minWidth: '200px',
					width: '50%',
					maxWidth: '300px',
					minHeight: '50px',
					backgroundColor: currencyColor,
					borderRadius: '5px',
					color: 'white',
				}}
				onClick={() => {
					navigate('new');
				}}
			>
				<Box sx={{ width: '100%' }}>
					<Typography fontSize={14} lineHeight={1.25} fontWeight={600}>
						{`Nueva publicación de compra ${currency}`}
					</Typography>
				</Box>
				<AddIcon />
			</Fab>
		</Box>
	);
};

export default BuyingDashboard;
