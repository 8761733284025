import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
	CambiappContext,
	CambiappContextType,
} from '../context/cambiapp.context';
import { currenciesFormater } from '../utils/currencies/currencies-formater';
import { CommonButton } from './button/common-button';
import { BatchTable } from './new-order-steps/batch-table/editable-batch-table.component';
import { BatchTableRow } from './new-order-steps/batch-table/editable-batch-table-row.component';
import { asyncHandler, BatchesDto } from '../utils/async-handler';
import { Currency } from '../interfaces/publication.interface';

export const ChangeQttyComponent = ({
	children,
	data,
	onClose,
	onChangeTotalAmountChanged,
}: {
	children: { [key: string]: any };
	data: {
		listingBatches: BatchesDto[];
		totalAmount: number;
		isPartialAllowed: boolean;
		minimunPartialAmount: number;
		unitPrice: number;
		minimumDenominationToReceive: string;
	};
	onClose: () => void;
	onChangeTotalAmountChanged: (changed: boolean) => void;
}) => {
	const { statuses, currency } = useContext(
		CambiappContext,
	) as CambiappContextType;
	const [listingBatches, setListingBatches] = useState(data.listingBatches);
	const [errorMessage, setErrorMessage] = useState('');
	const [calculateAmount, setCalculateAmount] = useState(data.totalAmount);
	const [hasDeletedBatch, setHasDeletedBatch] = useState(false);

	useEffect(() => {
		setListingBatches(data.listingBatches);
		setHasDeletedBatch(false);
	}, [data, data.listingBatches]);

	const handleDeleteRow = (index: number) => {
		if (hasDeletedBatch) {
			setErrorMessage('No se puede eliminar más de un lote');
		} else if (listingBatches.length === 1) {
			setErrorMessage('No se puede eliminar el único lote disponible');
		} else {
			const updatedListingBatches = [...listingBatches];
			updatedListingBatches.splice(index, 1);
			setListingBatches(updatedListingBatches);
			setHasDeletedBatch(true);
		}
	};

	const handleError = (errorInfo: { message: string } | null) => {
		setErrorMessage(errorInfo?.message || '');
	};

	const handleConfirm = () => {
		asyncHandler.changePublicationListingBatches(
			children.id,
			listingBatches,
			() => {
				onClose();
				onChangeTotalAmountChanged(true);
			},
		);
	};

	const totalAmount = async (listingBatches: BatchesDto[]) => {
		return listingBatches.reduce(function (total, batch) {
			return total + batch.amount;
		}, 0);
	};

	const setEditableAmount = async (amount: number, index: number) => {
		listingBatches[index].amount = amount;
		await setListingBatches(listingBatches);
		setCalculateAmount(await totalAmount(listingBatches));
	};

	return (
		<Box
			sx={{
				bgcolor: 'background.paper',
				borderRadius: '4px',
				padding: '20px',
				margin: '30px',
			}}
		>
			<Typography
				sx={{
					textAlign: 'center',
					fontWeight: 600,
					fontSize: 18,
					marginBottom: '30px',
				}}
			>
				Cambie las cantidades
			</Typography>
			<Typography sx={{ marginBottom: '16px', fontWeight: 800 }}>
				Resumen de los lotes
			</Typography>
			<BatchTable>
				{listingBatches.length > 0 ? (
					listingBatches.map((batch, index) => (
						<BatchTableRow
							key={index}
							amount={batch.amount}
							denomination={batch.billDenomination.split('-')[0]}
							descriptionTooltip={batch.billVersion.split('/')[1]}
							versionColor={batch.billColor}
							versionName={batch.billVersion.split('/')[0]}
							statusTooltip={batch.billStatus}
							statusName={
								statuses.data && statuses.data.indexOf(batch.billStatus) + 1
							}
							onDelete={() => handleDeleteRow(index)}
							data={{
								isPartialAllowed: data.isPartialAllowed,
								minimunPartialAmount: data.minimunPartialAmount,
							}}
							onError={handleError}
							setEditableAmount={(amount) => setEditableAmount(amount, index)}
						/>
					))
				) : (
					<Typography
						sx={{
							fontStyle: 'italic',
							margin: '10px',
						}}
					>
						No quedan elementos por mostrar
					</Typography>
				)}
			</BatchTable>
			{errorMessage && (
				<Typography sx={{ color: 'error.main', marginTop: '16px' }}>
					{errorMessage}
				</Typography>
			)}
			<Typography
				sx={{
					marginTop: '16px',
				}}
			>
				Cantidad total a publicar: {currency}{' '}
				{currenciesFormater(calculateAmount)}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'flex-end',
					justifyContent: 'space-between',
					margin: '10px',
				}}
			>
				<CommonButton action={onClose} sxBgcolor={'#666666'} maxHeight="40px">
					<Typography fontSize={12} color={'white'} fontWeight={600}>
						ATRÁS
					</Typography>
				</CommonButton>
				<CommonButton
					action={handleConfirm}
					sxBgcolor={'#05A550'}
					maxHeight="40px"
				>
					<Typography fontSize={12} color={'white'} fontWeight={600}>
						GUARDAR CAMBIOS
					</Typography>
				</CommonButton>
			</Box>
		</Box>
	);
};
