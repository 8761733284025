import { Box, Button } from '@mui/material';

interface CommonModalProps {
	children: React.ReactNode;
	active: boolean;
	close: () => void;
	confirmButtonAction?: () => void;
	cancelButtonAction?: () => void;
}

export const CommonModal = ({
	children,
	active,
	close,
	confirmButtonAction,
	cancelButtonAction,
}: CommonModalProps) => {
	return (
		<>
			{active && (
				<Box
					sx={{
						position: 'fixed',
						top: '0',
						width: '100%',
						height: '100%',
						backgroundColor: '#00000088',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Box
						sx={{
							padding: '15px',
							position: 'fixed',
							top: '50%',
							minWidth: '300px',
							width: '90%',
							maxWidth: '768px',
							backgroundColor: '#f0f0f0',
							color: 'black',
							transform: 'translateY(-50%)',
							backdropFilter: 'opacity(50%) blur(2px)',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Button
							color="inherit"
							sx={{ alignSelf: 'flex-end' }}
							onClick={close}
						>
							X
						</Button>
						<Box sx={{ margin: '15px 0' }}>{children}</Box>
						<Box
							sx={{
								alignSelf: 'flex-end',
								display: 'flex',
								flexDirection: 'row-reverse',
								columnGap: '16px',
							}}
						>
							{confirmButtonAction && (
								<Button variant="contained" onClick={confirmButtonAction}>
									ACEPTAR
								</Button>
							)}
							{cancelButtonAction && (
								<Button variant="contained" onClick={cancelButtonAction}>
									CANCELAR
								</Button>
							)}
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
};
