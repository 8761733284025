import { styled } from '@mui/material/styles';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import { stepIconClasses } from '@mui/material/StepIcon';
import { Step, Stepper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

interface IStepComponent {
	activeStep: number;
}

const StyledSellStepComponent = styled(StepLabel)(({ theme }) => ({
	[`.${stepLabelClasses.active}`]: {
		color: `#05A550 !important`,
		[`.${stepIconClasses.text}`]: {
			fill: `${theme.palette.action.active} !important`,
		},
	},
	[`.${stepLabelClasses.completed}`]: {
		color: `#05A550 !important`,
		backgroundColor: `${theme.palette.action.active} !important`,
		borderRadius: '50% !important',
	},
}));

const StyledBuyStepComponent = styled(StepLabel)(({ theme }) => ({
	[`.${stepLabelClasses.active}`]: {
		color: `#05A550 !important`,
		[`.${stepIconClasses.text}`]: {
			fill: `${theme.palette.action.active} !important`,
		},
	},
	[`.${stepLabelClasses.completed}`]: {
		color: `#05A550 !important`,
		[`.${stepIconClasses.text}`]: {
			fill: `${theme.palette.action.active} !important`,
		},
		backgroundColor: `${theme.palette.action.active} !important`,
		borderRadius: '50% !important',
	},
}));

export const StepperComponent = ({ activeStep }: IStepComponent) => {
	const steps = [1, 2, 3, 4];
	const location = useLocation();
	const navigate = useNavigate();

	window.addEventListener('load', function (event) {
		navigate('/');
	});

	return (
		<Stepper
			activeStep={activeStep}
			alternativeLabel
			sx={{ marginBottom: '25px' }}
		>
			{steps.map((label) => {
				return (
					<Step key={label}>
						{location.pathname.includes('sell') && <StyledSellStepComponent />}
						{location.pathname.includes('buy') && <StyledBuyStepComponent />}
					</Step>
				);
			})}
		</Stepper>
	);
};
